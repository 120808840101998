import React, { useState, useContext, createContext } from 'react';
import LoadingSpinner from '../../TailwindComponents/LoadingSpinner';
import './loading.css';

const LoadingContext = createContext();

const LoadingProvider = ({ children }) => {
  const [blocking, setBlocking] = useState(false);

  const showLoading = () => setBlocking(true);
  const hideLoading = () => setBlocking(false);

  const loadingContext = {
    showLoading,
    hideLoading,
    isLoading: blocking,
  };

  return (
    <LoadingContext.Provider value={loadingContext}>
      {blocking && <LoadingSpinner fullPage />}
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => useContext(LoadingContext);

export { LoadingProvider, useLoading };
