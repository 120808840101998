import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DropdownMenu from '../DropdownMenu';
import getColorClasses from '../../lib/getColorClasses';
import { ReactComponent as SalesIcn } from '../../../../icons/sales-icon.svg';
import { ReactComponent as DropdownIcn } from '../../../../icons/dropdown-icon.svg';
import LabelItem from '../LabelItem';
import LabelSubItem from '../LabelSubItem';

const Vendite = ({ currentPath }) => {
  const { t: tPages } = useTranslation('PAGES');
  const { t } = useTranslation('DOCUMENTS');
  const validPaths = [
    '/app/quotes',
    '/app/orders',
    '/app/ddts',
    '/app/proformas',
    '/app/invoices',
    '/app/creditnotes',
    '/app/workreports',
  ];

  return (
    <DropdownMenu
      Icon={<SalesIcn className={getColorClasses(currentPath, validPaths)} />}
      text={
        <LabelItem className={getColorClasses(currentPath, validPaths)}>
          {tPages('SALES')}
          <DropdownIcn className="inline fill-inherit h-2" />
        </LabelItem>
      }>
      <Link to="/app/quotes">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/quotes'])}>{t('QUOTES')}</LabelSubItem>
      </Link>
      {/* <Link to="/app/orders">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/orders'])}>{t('ORDERS')}</LabelSubItem>
      </Link> */}
      <Link to="/app/ddts">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/ddts'])}>{t('DDTS')}</LabelSubItem>
      </Link>
      <Link to="/app/proformas">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/proformas'])}>{t('PROFORMA')} </LabelSubItem>
      </Link>
      <Link to="/app/invoices">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/invoices'])}>{t('INVOICES')}</LabelSubItem>
      </Link>
      <Link to="/app/creditnotes">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/creditnotes'])}>{t('CREDITNOTES')}</LabelSubItem>
      </Link>
      <Link to="/app/workreports">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/workreports'])}>{t('REPORTS')}</LabelSubItem>
      </Link>
    </DropdownMenu>
  );
};

export default Vendite;
