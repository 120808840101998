export const Currencies = [
  {
    description: 'CURRENCIES.OPTION_1',
    code: 'EUR',
    icon: 'icon-eur',
    symbol: '€',
    default: true,
  },
  {
    description: 'CURRENCIES.OPTION_2',
    code: 'USD',
    icon: 'icon-usd',
    symbol: '$',
  },
  {
    description: 'CURRENCIES.OPTION_3',
    code: 'CHF',
    icon: 'icon-usd',
    symbol: 'CHF',
  },
];

export const EsigibilitaIvaTypes = [
  {
    code: 'I',
    description: 'VAT_TYPES.OPTION_1',
  },
  {
    code: 'D',
    description: 'VAT_TYPES.OPTION_2',
  },
  {
    code: 'S',
    description: 'VAT_TYPES.OPTION_3',
  },
];

export const TipiCassa = [
  {
    code: 'TC01',
    description: 'CASH_TYPES.OPTION_1',
  },
  {
    code: 'TC02',
    description: 'CASH_TYPES.OPTION_2',
  },
  {
    code: 'TC03',
    description: 'CASH_TYPES.OPTION_3',
  },
  {
    code: 'TC04',
    description: 'CASH_TYPES.OPTION_4',
  },
  {
    code: 'TC05',
    description: 'CASH_TYPES.OPTION_5',
  },
  {
    code: 'TC06',
    description: 'CASH_TYPES.OPTION_6',
  },
  {
    code: 'TC07',
    description: 'CASH_TYPES.OPTION_7',
  },
  {
    code: 'TC08',
    description: 'CASH_TYPES.OPTION_8',
  },
  {
    code: 'TC09',
    description: 'CASH_TYPES.OPTION_9',
  },
  {
    code: 'TC10',
    description: 'CASH_TYPES.OPTION_10',
  },
  {
    code: 'TC11',
    description: 'CASH_TYPES.OPTION_11',
  },
  {
    code: 'TC12',
    description: 'CASH_TYPES.OPTION_12',
  },
  {
    code: 'TC13',
    description: 'CASH_TYPES.OPTION_13',
  },
  {
    code: 'TC14',
    description: 'CASH_TYPES.OPTION_14',
  },
  {
    code: 'TC15',
    description: 'CASH_TYPES.OPTION_15',
  },
  {
    code: 'TC16',
    description: 'CASH_TYPES.OPTION_16',
  },
  {
    code: 'TC17',
    description: 'CASH_TYPES.OPTION_17',
  },
  {
    code: 'TC18',
    description: 'CASH_TYPES.OPTION_18',
  },
  {
    code: 'TC19',
    description: 'CASH_TYPES.OPTION_19',
  },
  {
    code: 'TC20',
    description: 'CASH_TYPES.OPTION_20',
  },
  {
    code: 'TC21',
    description: 'CASH_TYPES.OPTION_21',
  },
];

export const TipiDocumento = [
  {
    code: 'TD01',
    description: 'DOC_TYPES.OPTION_1',
  },
  {
    code: 'TD02',
    description: 'DOC_TYPES.OPTION_2',
  },
  {
    code: 'TD03',
    description: 'DOC_TYPES.OPTION_3',
  },
  {
    code: 'TD04',
    description: 'DOC_TYPES.OPTION_4',
  },
  {
    code: 'TD05',
    description: 'DOC_TYPES.OPTION_5',
  },
  {
    code: 'TD06',
    description: 'DOC_TYPES.OPTION_6',
  },
  {
    code: 'TD16',
    description: 'DOC_TYPES.OPTION_7',
  },
  {
    code: 'TD17',
    description: 'DOC_TYPES.OPTION_8',
  },
  {
    code: 'TD18',
    description: 'DOC_TYPES.OPTION_9',
  },
  {
    code: 'TD19',
    description: 'DOC_TYPES.OPTION_10',
  },
  {
    code: 'TD20',
    description: 'DOC_TYPES.OPTION_11',
  },
  {
    code: 'TD21',
    description: 'DOC_TYPES.OPTION_12',
  },
  {
    code: 'TD22',
    description: 'DOC_TYPES.OPTION_13',
  },
  {
    code: 'TD23',
    description: 'DOC_TYPES.OPTION_14',
  },
  {
    code: 'TD24',
    description: 'DOC_TYPES.OPTION_15',
  },
  {
    code: 'TD25',
    description: 'DOC_TYPES.OPTION_16',
  },
  {
    code: 'TD26',
    description: 'DOC_TYPES.OPTION_17',
  },
  {
    code: 'TD27',
    description: 'DOC_TYPES.OPTION_18',
  },
  {
    code: 'TD28',
    description: 'DOC_TYPES.OPTION_19',
  },
];

export const TipiNature = [
  {
    code: '',
    description: '-',
  },
  {
    code: 'N1',
    description: 'NATURE_TYPES.OPTION_1',
  },
  {
    code: 'N2',
    description: 'NATURE_TYPES.OPTION_2',
  },
  {
    code: 'N2.1',
    description: 'NATURE_TYPES.OPTION_3',
  },
  {
    code: 'N2.2',
    description: 'NATURE_TYPES.OPTION_4',
  },
  {
    code: 'N3',
    description: 'NATURE_TYPES.OPTION_5',
  },
  {
    code: 'N3.1',
    description: 'NATURE_TYPES.OPTION_6',
  },
  {
    code: 'N3.2',
    description: 'NATURE_TYPES.OPTION_7',
  },
  {
    code: 'N3.3',
    description: 'NATURE_TYPES.OPTION_8',
  },
  {
    code: 'N3.4',
    description: 'NATURE_TYPES.OPTION_9',
  },
  {
    code: 'N3.5',
    description: 'NATURE_TYPES.OPTION_10',
  },
  {
    code: 'N3.6',
    description: 'NATURE_TYPES.OPTION_11',
  },
  {
    code: 'N4',
    description: 'NATURE_TYPES.OPTION_12',
  },
  {
    code: 'N5',
    description: 'NATURE_TYPES.OPTION_13',
  },
  {
    code: 'N6',
    description: 'NATURE_TYPES.OPTION_14',
  },
  {
    code: 'N6.1',
    description: 'NATURE_TYPES.OPTION_15',
  },
  {
    code: 'N6.2',
    description: 'NATURE_TYPES.OPTION_16',
  },
  {
    code: 'N6.3',
    description: 'NATURE_TYPES.OPTION_17',
  },
  {
    code: 'N6.4',
    description: 'NATURE_TYPES.OPTION_18',
  },
  {
    code: 'N6.5',
    description: 'NATURE_TYPES.OPTION_19',
  },
  {
    code: 'N6.6',
    description: 'NATURE_TYPES.OPTION_20',
  },
  {
    code: 'N6.7',
    description: 'NATURE_TYPES.OPTION_20',
  },
  {
    code: 'N6.8',
    description: 'NATURE_TYPES.OPTION_21',
  },
  {
    code: 'N6.9',
    description: 'NATURE_TYPES.OPTION_22',
  },
  {
    code: 'N7',
    description: 'NATURE_TYPES.OPTION_23',
  },
];

export const SubscriptionPrices = Object.freeze({
  free: 0,
  forfettari: 25,
  einvoice: 96,
  ei_send: 240,
  teams: 480,
});

export const SubscriptionMonthlyFullPrices = Object.freeze({
  free: 0,
  forfettari: 30,
  einvoice: 120,
  ei_send: 288,
  teams: 576,
});

export const MonthlySubscriptionPrices = Object.freeze({
  free: 0,
  forfettari: 2.5,
  einvoice: 10,
  ei_send: 24,
  teams: 48,
});

export const PaymentModes = [
  {
    code: 'MP05',
    description: 'PAYMENT_MODES.OPTION_1',
  },
  {
    code: 'MP02',
    description: 'PAYMENT_MODES.OPTION_2',
  },
  {
    code: 'MP01',
    description: 'PAYMENT_MODES.OPTION_3',
  },
  {
    code: 'MP08',
    description: 'PAYMENT_MODES.OPTION_4',
  },
  {
    code: 'MP12',
    description: 'PAYMENT_MODES.OPTION_5',
  },
  {
    code: 'MP09',
    description: 'PAYMENT_MODES.OPTION_6',
  },
  {
    code: 'MP13',
    description: 'PAYMENT_MODES.OPTION_7',
  },
  {
    code: 'MP15',
    description: 'PAYMENT_MODES.OPTION_8',
  },
  {
    code: 'MP19',
    description: 'PAYMENT_MODES.OPTION_9',
  },
  {
    code: 'MP23',
    description: 'PAYMENT_MODES.OPTION_10',
  },
];

export const DocumentTypes = [
  {
    code: 'Invoice',
    description: 'DOCUMENT_TYPES.OPTION_1',
    type: 'income',
  },
  {
    code: 'CreditnoteIn',
    description: 'DOCUMENT_TYPES.OPTION_2',
    type: 'income',
  },
  {
    code: 'InvoiceIn',
    description: 'DOCUMENT_TYPES.OPTION_3',
    type: 'outcome',
  },
  {
    code: 'Creditnote',
    description: 'DOCUMENT_TYPES.OPTION_4',
    type: 'outcome',
  },
  {
    code: 'Expense',
    description: 'DOCUMENT_TYPES.OPTION_5',
    type: 'outcome',
  },
];

export const FabrickPaymentModesMapping = [
  {
    fabrickOp: 'ass',
    pinvOp: 'MP02',
  },
  {
    fabrickOp: 'ppa',
    pinvOp: 'MP23',
  },
  {
    fabrickOp: 'sdd',
    pinvOp: 'MP19',
  },
  {
    fabrickOp: 'ccr',
    pinvOp: 'MP08',
  },
  {
    fabrickOp: 'pre',
    pinvOp: 'MP08',
  },
  {
    fabrickOp: 'crd',
    pinvOp: 'MP08',
  },
  {
    fabrickOp: 'crg',
    pinvOp: 'MP08',
  },
  {
    fabrickOp: 'atm',
    pinvOp: 'MP08',
  },
  {
    fabrickOp: 'pos',
    pinvOp: 'MP08',
  },
  {
    fabrickOp: 'bon',
    pinvOp: 'MP05',
  },
  {
    fabrickOp: 'boi',
    pinvOp: 'MP05',
  },
  {
    fabrickOp: 'bap',
    pinvOp: 'MP05',
  },
  {
    fabrickOp: 'baf',
    pinvOp: 'MP05',
  },
  {
    fabrickOp: 'bop',
    pinvOp: 'MP05',
  },
  {
    fabrickOp: 'bog',
    pinvOp: 'MP05',
  },
  {
    fabrickOp: 'gir',
    pinvOp: 'MP15',
  },
  {
    fabrickOp: 'mav',
    pinvOp: 'MP13',
  },
  {
    fabrickOp: 'rib',
    pinvOp: 'MP12',
  },
];

export const RegimiFiscali = [
  {
    code: 'RF01',
    description: 'TAX_REGIME.OPTION_1',
  },
  {
    code: 'RF02',
    description: 'TAX_REGIME.OPTION_2',
  },
  {
    code: 'RF04',
    description: 'TAX_REGIME.OPTION_3',
  },
  {
    code: 'RF05',
    description: 'TAX_REGIME.OPTION_4',
  },
  {
    code: 'RF06',
    description: 'TAX_REGIME.OPTION_5',
  },
  {
    code: 'RF07',
    description: 'TAX_REGIME.OPTION_6',
  },
  {
    code: 'RF08',
    description: 'TAX_REGIME.OPTION_7',
  },
  {
    code: 'RF09',
    description: 'TAX_REGIME.OPTION_8',
  },
  {
    code: 'RF10',
    description: 'TAX_REGIME.OPTION_9',
  },
  {
    code: 'RF11',
    description: 'TAX_REGIME.OPTION_10',
  },
  {
    code: 'RF12',
    description: 'TAX_REGIME.OPTION_11',
  },
  {
    code: 'RF13',
    description: 'TAX_REGIME.OPTION_12',
  },
  {
    code: 'RF14',
    description: 'TAX_REGIME.OPTION_13',
  },
  {
    code: 'RF15',
    description: 'TAX_REGIME.OPTION_14',
  },
  {
    code: 'RF16',
    description: 'TAX_REGIME.OPTION_15',
  },
  {
    code: 'RF17',
    description: 'TAX_REGIME.OPTION_16',
  },
  {
    code: 'RF18',
    description: 'TAX_REGIME.OPTION_17',
  },
  {
    code: 'RF19',
    description: 'TAX_REGIME.OPTION_18',
  },
];

export const TipiRitenuta = [
  {
    code: '',
    description: '-',
  },
  {
    code: 'RT01',
    description: 'WITHHOLDING_TYPES.OPTION_1',
  },
  {
    code: 'RT02',
    description: 'WITHHOLDING_TYPES.OPTION_2',
  },
  {
    code: 'RT03',
    description: 'WITHHOLDING_TYPES.OPTION_3',
  },
  {
    code: 'RT04',
    description: 'WITHHOLDING_TYPES.OPTION_4',
  },
  {
    code: 'RT05',
    description: 'WITHHOLDING_TYPES.OPTION_5',
  },
  {
    code: 'RT06',
    description: 'WITHHOLDING_TYPES.OPTION_6',
  },
];

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

export const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/; //eslint-disable-line

export const IBAN_REGEX = /^[A-Z]{2}([a-zA-Z0-9]){13,33}$/; //eslint-disable-line

export const CODICE_FISCALE_REGEX = /^([a-zA-Z0-9]){11,16}$/; //eslint-disable-line

export const CATEGORIES_TYPE = {
  IN: 'IN',
  OUT: 'OUT',
};

export const CategoriesOut = [
  {
    name: 'Fatture',
    category: 'services',
  },
  {
    name: 'Corrispettivi',
    category: 'goods',
  },
  {
    name: 'Rimborsi e altre entrate',
    category: 'other',
  },
];

export const CategoriesIn = [
  {
    id: '1',
    name: 'Costi materie prime, di consumo e merci',
    category: 'goods',
  },
  {
    id: '2',
    name: 'Spese per automezzi',
    category: 'car',
    categories: [
      { id: '2a', name: 'prova 1' },
      { id: '2b', name: 'prova 2' },
    ],
  },
  {
    id: '3',
    name: 'Costi di amministrazione',
    category: 'adminCost',
  },
  {
    id: '4',
    name: 'Compensi amministratori',
    category: 'adminTaxes',
  },
  {
    id: '5',
    name: 'Onorari professionali',
    category: 'accountant',
  },
  {
    id: '6',
    name: 'Utenze e approvvigionamenti',
    category: 'utilities',
  },
  {
    id: '7',
    name: 'Spese per viaggi di rappresentanza',
    category: 'travel',
  },
  {
    id: '8',
    name: 'Costi di manodopera e servizi',
    category: 'services',
  },
  {
    id: '9',
    name: 'Spese bancarie',
    category: 'bank',
  },
  {
    id: '10',
    name: 'Spese per pubblicità e promozioni',
    category: 'advertising',
  },
  {
    id: '11',
    name: 'Giroconto',
    category: 'associate',
  },
  {
    id: '12',
    name: 'Spese carta di credito',
    category: 'charges',
  },
  {
    id: '13',
    name: 'Affitti',
    category: 'rent',
  },
  {
    id: '14',
    name: 'Canoni di Leasing',
    category: 'leasing',
  },
  {
    id: '15',
    name: 'Stipendi',
    category: 'paychecks',
  },
  {
    id: '16',
    name: 'Ricerca, addestramento, formazione',
    category: 'formation',
  },
  {
    id: '17',
    name: 'Imposte e tasse',
    category: 'taxes',
  },
  {
    id: '18',
    name: 'Rate mutui e finanziamenti',
    category: 'loan',
  },
  {
    id: '19',
    name: 'IVA',
    category: 'vat',
  },
  {
    id: '20',
    name: 'Costi secondari',
    category: 'insurance',
  },
  {
    id: '21',
    name: 'Altro',
    category: 'other',
  },
];

//reconciliation

export const DOC_TYPES = {
  ACCOMPANYING_INVOICE: 'accompanyingInvoice',
  INVOICE: 'invoice',
  INVOICEIN: 'invoiceIn',
  CREDITNOTE: 'creditnote',
  CREDITNOTEIN: 'creditnoteIn',
  EXPENSE: 'expense',
  QUOTE: 'quote',
  DDT: 'ddt',
  PROFORMA: 'proforma',
  ORDER: 'order',
  WORKREPORT: 'workreport',
};

export const DOC_STATUS = {
  INSOLUTO: 'DOC_STATUS.OPTION_1',
  DELIVERED: 'DOC_STATUS.OPTION_2',
  DRAFT: 'DOC_STATUS.OPTION_3',
  MANCATA_CONSEGNA: 'DOC_STATUS.OPTION_4',
  ACCETTATA: 'DOC_STATUS.OPTION_5',
  CONSEGNATA: 'DOC_STATUS.OPTION_6',
  SCARTATA: 'DOC_STATUS.OPTION_7',
};

export const PAYMENT_STATUS_IN = {
  PAYED: 'PAYMENT_STATUS_IN.OPTION_1',
  PARTIALLY_PAYED: 'PAYMENT_STATUS_IN.OPTION_2',
  NOT_PAYED: 'PAYMENT_STATUS_IN.OPTION_3',
};

export const PAYMENT_STATUS_OUT = {
  PAYED: 'PAYMENT_STATUS_OUT.OPTION_1',
  PARTIALLY_PAYED: 'PAYMENT_STATUS_OUT.OPTION_2',
  NOT_PAYED: 'PAYMENT_STATUS_OUT.OPTION_3',
};

export const BILLING_TIME = {
  MONTHLY: 'month',
  YEARLY: 'year',
};

export const ANALYTIC_EVENTS = {
  USER_AS_FORFETTARIO: 'userAsForfettario',
  USER_AS_PMI: 'UserAsPmi',
  CHECK_EMAIL: 'checkEmail',
  CHECK_COMPANY_DATA_DASHBOARD: 'checkCompanyData_dashboard',
  CHECK_FIRST_INVOICE_DASHBOARD: 'checkfirstInvoice_dashboard',
  SAVE_COMPANY_DATA: 'saveCompanyData',
  CLICK_SEND_INVOICE: 'clickSendInvoice',
  CLICK_SAVE_INVOICE: 'clickSaveInvoice',
  SEE_SCHEDULER_SAVING_TOAST: 'seeScheduler_savingToast',
  EDIT_INVOICE_SAVING_TOAST: 'editInvoiceIn_savingToast',
  CHECK_COMPANY_DATA_INVOICE: 'checkCompanyData_invoice',
  CANCEL_SEND_INVOICE: 'cancelSend_invoiceIn',
};
