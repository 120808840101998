import { RECONCILIATION_ALLOWED_PLANS, WORKSPACE_PLANS } from './constants';

// Funzione per ottenere il piano di sottoscrizione
const getSubscriptionPlan = (): string => {
  return localStorage.getItem('pf.plan') || '';
};

// Funzione per salvare il piano di sottoscrizione
const saveSubscriptionPlan = (subscription: { plan?: string }): void => {
  const { plan = '' } = subscription;
  localStorage.setItem('pf.plan', plan);
};

// Funzione per eliminare il piano di sottoscrizione
const deleteSubscriptionPlan = (): void => {
  localStorage.removeItem('pf.plan');
};

// Funzione per verificare se la riconciliazione è consentita
const isReconciliationAllowed = (): boolean => {
  const plan = getSubscriptionPlan() as never;
  return RECONCILIATION_ALLOWED_PLANS.includes(plan);
};

// Funzione per verificare se l'invito utente è consentito
const isUserInvitationAllowed = (): boolean => {
  const plan = getSubscriptionPlan();
  return WORKSPACE_PLANS.TEAMS === plan;
};

// Funzione per verificare se l'invito collaboratore è consentito
const isCollabsInvitationAllowed = (actualCollabs = 0): boolean => {
  const plan = getSubscriptionPlan();
  if (WORKSPACE_PLANS.TEAMS === plan) return true;
  return actualCollabs < 1;
};

// Funzione per verificare se la sottoscrizione è attiva
const isSubscriptionActive = (workspace: { subscription?: { suspended_at?: string } } = {}): boolean => {
  const { subscription = {} } = workspace;
  const { suspended_at } = subscription;
  return !!(suspended_at && Date.parse(suspended_at) > Date.now());
};

// Funzione per verificare se la sottoscrizione è scaduta
const isSubscriptionExpired = (workspace: { subscription?: { suspended_at?: string } } = {}): boolean => {
  return !isSubscriptionActive(workspace);
};

// Funzione per verificare se il workspace è in prova
const isWorkspaceInTrial = (workspace: { subscription?: { trial_active?: boolean } } = {}): boolean => {
  const { subscription = {} } = workspace;
  const { trial_active = false } = subscription;
  return trial_active;
};

export {
  getSubscriptionPlan,
  saveSubscriptionPlan,
  deleteSubscriptionPlan,
  isReconciliationAllowed,
  isUserInvitationAllowed,
  isCollabsInvitationAllowed,
  isSubscriptionActive,
  isSubscriptionExpired,
  isWorkspaceInTrial,
};
