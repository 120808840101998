import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import SuspenseFallback from '../SuspenseFallback';

// App layout whithout menu and with content in full width
const LayoutFullWidth = () => {
  return (
    <main id="app" className="bg-neutro-100">
      <section id="content" className="bg-neutro-100">
        <Suspense fallback={<SuspenseFallback />}>
          <Outlet />
        </Suspense>
      </section>
    </main>
  );
};

export default LayoutFullWidth;
