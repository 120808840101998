import { USER_CAPABILITIES } from '../../constants';

export const allCapabilities = () => {
  return Object.values(USER_CAPABILITIES);
};

export const isWhiteLabel = () => {
  const brand = process.env.REACT_APP_BRAND;
  if (brand !== 'pinv') {
    return true;
  }
  return false;
};

/**
 * Checks if the user is allowed to access the page.
 * @param {*} me The user object.
 * @param {*} allowedCapabilities The allowed capabilities.
 * @returns boolean true if the user is allowed to access the page, false otherwise.
 */
export const isAllowed = (me, allowedCapabilities) => {
  // if (isWhiteLabel()) {
  return me?.membership?.resources?.some((role) => allowedCapabilities.includes(role));
  // }
  // return true;
};
