import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DropdownMenu from '../DropdownMenu';
import getColorClasses from '../../lib/getColorClasses';
import { ReactComponent as PurchaseIcn } from '../../../../icons/purchases-icon.svg';
import { ReactComponent as DropdownIcn } from '../../../../icons/dropdown-icon.svg';
import LabelItem from '../LabelItem';
import LabelSubItem from '../LabelSubItem';

const Acquisti = ({ currentPath }) => {
  const { t: tPages } = useTranslation('PAGES');
  const { t } = useTranslation('DOCUMENTS');
  const validPaths = ['/app/invoices-in', '/app/creditnotes-in', '/app/expenses'];

  return (
    <DropdownMenu
      Icon={<PurchaseIcn className={getColorClasses(currentPath, validPaths)} />}
      text={
        <LabelItem className={getColorClasses(currentPath, validPaths)}>
          {tPages('PURCHASES')} <DropdownIcn className="inline fill-inherit h-2" />
        </LabelItem>
      }>
      <Link to="/app/invoices-in">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/invoices-in'])}>
          {t('PURCHASE_INVOICES')}
        </LabelSubItem>
      </Link>
      <Link to="/app/creditnotes-in">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/creditnotes-in'])}>
          {t('SUPPLIER_CREDITNOTES')}
        </LabelSubItem>
      </Link>
      <Link to="/app/expenses">
        <LabelSubItem className={getColorClasses(currentPath, ['/app/expenses'])}>{t('EXPENSES')}</LabelSubItem>
      </Link>
    </DropdownMenu>
  );
};

export default Acquisti;
