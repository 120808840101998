import React from 'react';

const LabelItem = ({ children, className = '' }) => (
  <div>
    <span className={`mt-[2px] menu-opacity-transition absolute ml-3 font-light text-sm font-menu ${className}`}>
      {children}
    </span>
  </div>
);

export default LabelItem;
