import { makeHttpPost } from '../apiClient';
import { deleteStorage } from '../utils/storage';
import { deleteToken, getToken, saveToken } from '../token';
import { getUserId, saveUserId } from '../user';
import { AUTH_TOKEN_NAME, TOKEN_NAME } from '../constants';
import { saveWorkspaceId } from '../workspace';
import { saveSubscriptionPlan } from '../subscription';

/**
 * Do the login to the app
 * @param {object} requestData Object with email and password props: { email: 'email@example.com', password: 'password' }
 * @returns Response object if success or Error object otherwise
 */
const login = async (requestData) => {
  try {
    const responseData = await makeHttpPost(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/login?appId=${process.env.REACT_APP_AUTH_APP_ID}`,
      requestData,
    );

    saveAuthDataToClient(responseData);

    return responseData;
  } catch (error) {
    deleteToken(AUTH_TOKEN_NAME);
    deleteStorage();

    throw error;
  }
};

/**
 * Save the auth data to the client into cookies
 * @param {*} responseData
 */
export const saveAuthDataToClient = (responseData) => {
  const { token, _id } = responseData;

  if (_id !== getUserId()) {
    deleteStorage();
  }

  saveToken(AUTH_TOKEN_NAME, token);

  if (_id) {
    saveUserId(_id);
  }
};

export default login;

/**
 * Login invited user with email, password and auth app id
 * @param {*} email
 * @param {*} password
 * @returns The auth token if success, otherwise throws an error
 */
export const loginInvitedUser = async (email, password) => {
  const payload = {
    appId: process.env.REACT_APP_AUTH_APP_ID,
    email,
    password,
  };

  try {
    const responseData = await makeHttpPost(`${process.env.REACT_APP_AUTH_SERVER_URL}/login`, payload);

    saveAuthDataToClient(responseData);

    return responseData;
  } catch (error) {
    throw error;
  }
};

export const saveDataToClient = (meResponse) => {
  const { _workspace: workspace, subscription, token } = meResponse || {};

  saveWorkspaceId(workspace);
  saveSubscriptionPlan(subscription);

  if (!getToken(TOKEN_NAME)) {
    saveToken(TOKEN_NAME, token);
  }
};
