const getUserId = () => {
  return localStorage.getItem('pf.userid') || 0;
};

const saveUserId = (id) => {
  return localStorage.setItem('pf.userid', id);
};

const deleteUserId = () => {
  localStorage.removeItem('pf.userid');
};

/**
 * Checks if the email of a user is verified.
 *
 * @param {Object} user - The user object containing information about the user.
 * @return {boolean} - True if the email is verified, false otherwise.
 */
const isEmailVerified = (user) => {
  const { emailVerified = false } = user;

  return emailVerified;
};

export { getUserId, saveUserId, deleteUserId, isEmailVerified };
