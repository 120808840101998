// Defines the cookie token names
export const AUTH_TOKEN_NAME = process.env.REACT_APP_AUTH_TOKEN_NAME || 'authToken';
export const TOKEN_NAME = 'token';

// Defines the React Query state names
export const WORKSPACE_QUERY_NAME = 'workpace';
export const ME_QUERY_NAME = 'me';
export const TAX_QUERY_NAME = 'tax';
export const CATEGORIES_QUERY_NAME = 'categories';
export const RECURRENCE_QUERY_NAME = 'recurrence';

// Defines the onboarding states
export const ONBOARDING_STATUS = Object.freeze({
  TO_CONFIRM_PLAN: 'to_confirm_plan',
  IN_TUTORIAL: 'in_tutorial',
  ONBOARDING_DONE: 'onboarding_done',
});

// Defines the onboarding tasks
export const ONBOARDING_TASKS = Object.freeze({
  CONTO_CORRENTE: 'contoCorrente',
  CASSETTO_FISCALE: 'cassettoFiscale',
  FIRST_INVOICE_CREATED: 'firstInvoiceCreated',
  EMAIL_VERIFIED: 'userEmailVerified',
  FIRST_FORECAST_CREATED: 'firstForecastCreated',
  CHECK_YOUR_DATA: 'checkYourData',
});

// Defines the app plans
export const WORKSPACE_PLANS = Object.freeze({
  EINVOICE: 'einvoice',
  SOLO: 'ei_send',
  TEAMS: 'teams',
  FORFETTARI: 'forfettari',
});

export const ROUTING_URLS = Object.freeze({
  DASHBOARD: 'app/dashboard',
  LOGIN: 'login',
  ONBOARDING: 'app/onboarding',
  ONBOARDING_SEDE_LEGALE: 'app/onboarding-sede-legale',
  SUBSCRIPTION: 'app/subscription',
  SPID_LOGIN: 'app/spid-login',
  PREVISIONS: 'app/previsions',
  NOAUTH: '/noauth',
});

export const BANK_TYPE = Object.freeze({
  MANUAL: 'manual',
  FABRICK: 'fabrick',
  SME: 'sme',
});

export const BANK_CIRCUIT_CODE = Object.freeze({
  PSD2: 'psd2',
  CBI: 'cbi',
  SELLA: 'sella',
  AIDEXA: 'aidexa',
});

// Defines the base path of the app
export const APP_BASE_PATH = 'app';

export const ITALY_VAT_LENGTH = 11;

// Defines the url not allowed if legal info of workspace is not defined
export const NOT_ALLOWED_BEFORE_ONBOARDING_LEGAL_INFO_ROUTING_URLS = [
  `${APP_BASE_PATH}/invoices`,
  `${APP_BASE_PATH}/invoices/:id`,
  `${APP_BASE_PATH}/invoices/create`,
  `${APP_BASE_PATH}/invoices-in`,
  `${APP_BASE_PATH}/invoices-in/:id`,
  `${APP_BASE_PATH}/invoices-in/create`,
  `${APP_BASE_PATH}/creditnotes`,
  `${APP_BASE_PATH}/creditnotes/:id`,
  `${APP_BASE_PATH}/creditnotes/create`,
  `${APP_BASE_PATH}/creditnotes-in`,
  `${APP_BASE_PATH}/creditnotes-in/:id`,
  `${APP_BASE_PATH}/creditnotes-in/create`,
  `${APP_BASE_PATH}/expenses`,
  `${APP_BASE_PATH}/expenses/:id`,
  `${APP_BASE_PATH}/expenses/create`,
  `${APP_BASE_PATH}/quotes`,
  `${APP_BASE_PATH}/quotes/:id`,
  `${APP_BASE_PATH}/quotes/create`,
  `${APP_BASE_PATH}/orders`,
  `${APP_BASE_PATH}/orders/:id`,
  `${APP_BASE_PATH}/orders/create`,
  `${APP_BASE_PATH}/ddts`,
  `${APP_BASE_PATH}/ddts/:id`,
  `${APP_BASE_PATH}/ddts/create`,
  `${APP_BASE_PATH}/proformas`,
  `${APP_BASE_PATH}/proformas/:id`,
  `${APP_BASE_PATH}/proformas/create`,
  `${APP_BASE_PATH}/workreports`,
  `${APP_BASE_PATH}/workreports/:id`,
  `${APP_BASE_PATH}/workreports/create`,
];

export const USER_CAPABILITIES = {
  CASHFLOW: 'cashflow',
  METRICS: 'metrics',
  BANKS: 'banks',
  DOC_IN: 'docIn',
  DOC_OUT: 'docOut',
  TAXES: 'taxes',
  CUSTOMERS: 'customers',
  DOCUMENTAL: 'documental',
  COMPANY: 'company',
  USERS: 'users',
  SUBSCRIBE: 'subscribe',
};

export const FILE_EXTENSIONS = Object.freeze({
  JPG: 'jpg',
});

export const APP_LAYOUTS = {
  WITH_MENU: 'withMenu',
  WITHOUT_MENU: 'withoutMenu',
  WITH_MENU_NO_PADDING: 'withMenuNoPadding',
  WITHOUT_MENU_NO_PADDING: 'withoutMenuNoPadding',
  FULL_WIDTH: 'fullWidth',
};

// Defines the app paths
export const APP_ROUTES = Object.freeze({
  DASHBOARD: 'dashboard',
  PREVISIONS: 'previsions',
  TEAM: 'team',
  WORKSPACE: 'workspace',
  SUBSCRIPTION: 'subscription',
  ONBOARDING: 'onboarding',
  ONBOARDING_SEDE_LEGALE: 'onboarding-sede-legale',
  METRICS: 'metrics',
  ACCOUNTS: 'accounts',
  MOVEMENTS_DETAIL: 'movements/:accountId',
  CREATE_MANUAL_BANK: 'create-manual-bank',
  CUSTOMERS: 'customers',
  CUSTOMERS_DETAIL: 'customers/:accountId',
  FINANCES: 'finances',
  DOCUMENTALE: 'documentale',
  RECURRRENCES: 'recurrences',
  PARTNERS: 'partners',
  PARTNERS_QONTO: 'partners/qonto',
  INVOICE: 'invoices',
  INVOICE_DETAIL: 'invoices/:id',
  INVOICE_CREATE: 'invoices/create',
  INVOICE_IN: 'invoices-in',
  INVOICE_IN_DETAIL: 'invoices-in/:id',
  INVOICE_IN_CREATE: 'invoices-in/create',
  CREDIT_NOTE: 'creditnotes',
  CREDIT_NOTE_DETAIL: 'creditnotes/:id',
  CREDIT_NOTE_CREATE: 'creditnotes/create',
  CREDIT_NOTEIN: 'creditnotes-in',
  CREDIT_NOTEIN_DETAIL: 'creditnotes-in/:id',
  CREDIT_NOTEIN_CREATE: 'creditnotes-in/create',
  EXPENSE: 'expenses',
  EXPENSE_DETAIL: 'expenses/:id',
  EXPENSE_CREATE: 'expenses/create',
  QUOTE: 'quotes',
  QUOTE_DETAIL: 'quotes/:id',
  QUOTE_CREATE: 'quotes/create',
  ORDER: 'orders',
  ORDER_DETAIL: 'orders/:id',
  ORDER_CREATE: 'orders/create',
  DDT: 'ddts',
  DDT_DETAIL: 'ddts/:id',
  DDT_CREATE: 'ddts/create',
  PROFORMA: 'proformas',
  PROFORMA_DETAIL: 'proformas/:id',
  PROFORMA_CREATE: 'proformas/create',
  WORKREPORT: 'workreports',
  WORKREPORT_DETAIL: 'workreports/:id',
  WORKREPORT_CREATE: 'workreports/create',
});

export const PLAN_NAME_FROM_SME = {
  BASIC: 'BASIC',
  EVOLVED: 'EVOLVED',
  TOP: 'TOP',
};

export const USER_ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
};

export const COLLABORATOR_ROLES = {
  BUSINESS_CONSULTANT: 'business_consultant',
};

export const RECONCILIATION_ALLOWED_PLANS = [WORKSPACE_PLANS.SOLO, WORKSPACE_PLANS.TEAMS];

export const TIERS = [
  {
    plan: 'Forfettari',
    price: 25,
    monthlyPrice: 2.5,
    code: 'forfettari',
    description: 'TIERS_CONSTANTS.PLAN_1',
  },

  {
    plan: 'Invoice',
    price: 96,
    monthlyPrice: 10,
    code: 'einvoice',
    description: 'TIERS_CONSTANTS.PLAN_2',
  },
  {
    plan: 'Solo',
    price: 240,
    monthlyPrice: 24,
    code: 'ei_send',
    description: 'TIERS_CONSTANTS.PLAN_3',
  },
  {
    plan: 'Team',
    price: 480,
    monthlyPrice: 48,
    code: 'teams',
    description: 'TIERS_CONSTANTS.PLAN_4',
  },
];

export const DOCUMENT_LOG_EVENT_CODE = Object.freeze({
  DOCUMENT_CREATED: 'DOCUMENT_CREATED',
  NOTIFICATION_DISCARDED: 'NOTIFICATION_DISCARDED',
  TOTALLY_RECONCILED: 'TOTALLY_RECONCILED',
  PARTIALLY_RECONCILED: 'PARTIALLY_RECONCILED',
  INVOICE_SENT: 'INVOICE_SENT',
  REMINDER_SENT: 'REMINDER_SENT',
  COURTESY_SENT: 'COURTESY_SENT',
});

export const DOCUMENT_LOG_SYSTEM_USER = 'SISTEMA';

export const COLLABORATOR_TYPES = Object.freeze({
  COLLABORATOR: 'collaborator',
  HOLDING: 'holding',
});
export const CASSETTO_FISCALE_DOC_TYPES_TO_DOWNLOAD = {
  FATTURA: 'FATTURA',
  CORRISPETTIVO: 'CORRISPETTIVO',
};
