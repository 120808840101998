import i18next from 'i18next';

import it from 'translations/it.json';
import en from 'translations/en.json';

export const LANGS = ['it', 'en'];
export const DEFAULT_LANG = LANGS[0];

export const translations = {
  it,
  en,
};

export const init = () => {
  i18next.init({
    resources: translations,
    lng: DEFAULT_LANG,
    // keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
};
