import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CashFlow from './components/items/CashFlow';
import Accounts from './components/items/Accounts';
import Metrics from './components/items/Metrics';
import UserProfile from './components/items/UserProfile';
import Subscription from './components/items/Subscription';
import Logout from './components/items/Logout';
import Help from './components/items/Help';
import CompanyProfile from './components/items/CompanyProfile';
import Documentale from './components/items/Documentale';
import Taxes from './components/items/Taxes';
import CustomerList from './components/items/CustomerList';
import Vendite from './components/items/Vendite';
import Acquisti from './components/items/Acquisti';
import Products from './components/items/Products';
import UserManagement from './components/items/UserManagement';
import Recurrences from './components/items/Recurrences';
import TitleList from './components/TitleList';
import Paragraph from './components/Paragraph';
import CompanyLabel from './components/items/CompanyLabel';
import { allCapabilities } from '../../../lib/auth/user/userHasPermission';
import IsAuthorized from '../../../routes/IsAuthorized';
import { USER_CAPABILITIES } from '../../../lib/constants';
import useWorkspace from '../../../hooks/useWorkspace';
import useMe from '../../../hooks/useMe';
import Lang from './components/items/Lang';

const Menu = () => {
  const { data: workspace } = useWorkspace();
  const { data: me } = useMe();
  const { pathname } = useLocation();
  // take care only the root for a menu path to color the text menu
  const rootPath = pathname.split('/', 3).join('/');
  const userVerified = me.emailVerified;
  const { t } = useTranslation('PAGES');

  return (
    <div className="ml-2 flex flex-col mb-10">
      <CompanyLabel me={me} workspace={workspace} />
      <IsAuthorized
        me={me}
        capabilities={[USER_CAPABILITIES.CASHFLOW, USER_CAPABILITIES.METRICS, USER_CAPABILITIES.BANKS]}>
        <Paragraph>
          <TitleList>{t('SECTION.CASHFLOW')}</TitleList>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.CASHFLOW]}>
            <CashFlow currentPath={rootPath} me={me} />
          </IsAuthorized>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.METRICS]}>
            <Metrics currentPath={rootPath} me={me} />
          </IsAuthorized>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.BANKS]}>
            <Accounts currentPath={rootPath} />
          </IsAuthorized>
        </Paragraph>
      </IsAuthorized>
      <IsAuthorized
        me={me}
        capabilities={[
          USER_CAPABILITIES.DOC_IN,
          USER_CAPABILITIES.DOC_OUT,
          USER_CAPABILITIES.CUSTOMERS,
          USER_CAPABILITIES.TAXES,
          USER_CAPABILITIES.DOCUMENTAL,
          USER_CAPABILITIES.COMPANY,
        ]}>
        <Paragraph>
          <TitleList>{t('SECTION.COMPANY_INVOICE')}</TitleList>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.DOC_IN]}>
            <Acquisti currentPath={rootPath} />
          </IsAuthorized>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.DOC_OUT]}>
            <Vendite currentPath={rootPath} />
          </IsAuthorized>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.DOC_OUT]}>
            <Products currentPath={rootPath} />
          </IsAuthorized>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.DOC_IN, USER_CAPABILITIES.DOC_OUT]}>
            <Recurrences currentPath={rootPath} />
          </IsAuthorized>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.CUSTOMERS]}>
            <CustomerList currentPath={rootPath} />
          </IsAuthorized>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.TAXES]}>
            <Taxes currentPath={rootPath} />
          </IsAuthorized>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.DOCUMENTAL]}>
            <Documentale currentPath={rootPath} me={me} />
          </IsAuthorized>
          <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.COMPANY]}>
            <CompanyProfile currentPath={rootPath} />
          </IsAuthorized>
        </Paragraph>
      </IsAuthorized>
      <Paragraph>
        <TitleList>{t('SECTION.USER')}</TitleList>
        <IsAuthorized me={me} capabilities={[USER_CAPABILITIES.USERS]}>
          <UserProfile currentPath={rootPath} userVerified={userVerified} />
        </IsAuthorized>
        <IsAuthorized me={me} capabilities={allCapabilities()} renderInWhiteLabel={false}>
          <UserManagement currentPath={rootPath} />
        </IsAuthorized>
        <IsAuthorized me={me} capabilities={allCapabilities()} renderInWhiteLabel={false}>
          <Subscription currentPath={rootPath} />
        </IsAuthorized>
        <Help currentPath={rootPath} />
        <IsAuthorized me={me} capabilities={allCapabilities()} renderInWhiteLabel={false}>
          <Logout />
        </IsAuthorized>
      </Paragraph>

      {process.env.REACT_APP_FEATURE_FLAG_CHANGE_LANG === 'true' && (
        <Paragraph>
          <Lang />
        </Paragraph>
      )}
    </div>
  );
};

export default Menu;
