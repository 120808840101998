import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'icons/logo.svg';

const CompanyLabel = ({ workspace = {}, me = {} }) => {
  const { t } = useTranslation('PAGES');
  const { t: tCommon } = useTranslation('COMMON');
  const companyName = workspace.company || tCommon('NOT_AVAILABLE');
  const firstLetter = Array.from(companyName)[0];
  const isMultyWorkspace = me.workspaces?.length > 1;
  const totalHeight = isMultyWorkspace ? 'h-[146px]' : 'h-[122px]';

  return (
    <>
      {/* Section when not in hover, height fixed to the hoverCompanyLabel's height */}
      <div className={`sticky top-0 bg-white group-hover:hidden ${totalHeight}`}>
        <div className="rounded-full bg-secondary-400 text-center uppercase font-bold text-white w-6 h-6 mt-14">
          {firstLetter}
        </div>
      </div>
      {/* Section when in hover */}
      <div className="group-hover:bg-white absolute group-hover:sticky top-0 z-[60] pb-5">
        <div className="bg-companyLabelBackground rounded mt-4 pt-3 pb-1 px-3 menu-opacity-transition">
          <Logo className="h-[14px] max-w-full" />
          <Link to="/app/workspace" className="block mt-2 no-underline">
            <p className="whitespace-nowrap">{t('COMPANY_PROFILE')}</p>
            <p className="font-semibold truncate">{companyName}</p>
          </Link>
          {isMultyWorkspace && (
            <Link to="/app/workspaces" className="whitespace-nowrap underline">
              {t('CHANGE_COMPANY')}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyLabel;
