import { createContext, useContext, useReducer, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userLifecycleReducer, Action, ActionType, UserLifecycleState } from './userLifecycleReducer';
import { handleSending, HandleSendingResult } from './userLifecycleHandler';

// Types definitions
// eslint-disable-next-line no-unused-vars
type Dispatch<A> = (action: A) => void;

// Context
interface UserLifecycleContextType<A> {
  state: UserLifecycleState;
  dispatch: Dispatch<A>;
  handleDocumentSending: () => Promise<HandleSendingResult>;
}

const UserLifecycleContext = createContext<UserLifecycleContextType<Action<ActionType>> | undefined>(undefined);

// Provider
interface UserLifecycleProviderProps {
  initialState: UserLifecycleState;
  children: ReactNode;
}

export function UserLifecycleProvider({ initialState, children }: UserLifecycleProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(userLifecycleReducer, initialState);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleDocumentSending = async () => {
    dispatch({ type: 'RETURN_PATH', payload: pathname });
    return await handleSending(state, navigate);
  };

  return (
    <UserLifecycleContext.Provider value={{ state, dispatch, handleDocumentSending }}>
      {children}
    </UserLifecycleContext.Provider>
  );
}

// Custom hooks
export const useUserLifecycleContext = (): UserLifecycleContextType<Action<ActionType>> => {
  const context = useContext(UserLifecycleContext);
  if (context === undefined) {
    throw new Error('useUserLifecycleContext must be used inside a UserLifecycleProvider');
  }
  return context as UserLifecycleContextType<Action<ActionType>>;
};
