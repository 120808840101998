// Cancellazione localstorage dell'applicazione
const deleteStorage = () => {
  // Scorro tutte le chiavi nel localstorage
  // ed elimino quelle che contengono le stringhe "pf."
  Object.keys(localStorage).forEach(function (key) {
    if (/^(pf.)/.test(key)) {
      localStorage.removeItem(key);
    }
  });
};

export { deleteStorage };
