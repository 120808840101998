import Cookies from 'js-cookie';

/**
 * Find if in the current url exists the 'deploy-preview' string then return true, false otherwise
 * @returns boolean
 */
const isDeployPreview = () => {
  return window.location.href.search('deploy-preview') === -1 ? false : true;
};

const cookieDomain = isDeployPreview() ? '' : process.env.REACT_APP_COOKIE_DOMAIN;
const cookiePath = '/';

const cookieOptions = {
  domain: cookieDomain,
  path: cookiePath,
};

const getToken = (name) => {
  if (!name) {
    throw new Error('The name parameter is required');
  }
  return Cookies.get(name);
};

const saveToken = (name, data) => {
  if (!name) {
    throw new Error('The name parameter is required');
  }
  Cookies.set(name, data, cookieOptions);
};

const deleteToken = (name) => {
  if (!name) {
    throw new Error('The name parameter is required');
  }
  if (getToken(name)) {
    Cookies.remove(name, cookieOptions);
  }
};

export { getToken, saveToken, deleteToken };
