import React, { useState } from 'react';
import ListItem from './ListItem';

const DropdownMenu = ({ children, Icon, text = '' }) => {
  const [open, setOpen] = useState(false);
  const display = open ? 'grid' : 'hidden';
  const toggleDropdown = () => setOpen(!open);

  return (
    <ListItem onMouseLeave={() => setOpen(false)}>
      <span onClick={toggleDropdown} className="flex">
        {Icon}
        {text}
      </span>
      <ul className={`cursor-pointer ml-0 mt-2 list-none ${display}`}>
        {children.map((child, key) => (
          <li key={key} onClick={toggleDropdown} className="list-item my-1 font-light">
            {child}
          </li>
        ))}
      </ul>
    </ListItem>
  );
};

export default DropdownMenu;
