import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LabelItem from '../LabelItem';
import ListItem from '../ListItem';
import { ReactComponent as LogoutIcn } from '../../../../icons/logout-icon.svg';
import getColorClasses from '../../lib/getColorClasses';
import { ROUTING_URLS } from '../../../../../lib/constants';
import { resetAuthDataFromClient } from '../../../../../lib/utils/utils';
import { useQueryClient } from '@tanstack/react-query';

const Logout = () => {
  const { t } = useTranslation('PAGES');
  const queryClient = useQueryClient();
  const validPaths = [];
  const navigate = useNavigate();

  const handleLogout = () => {
    try {
      resetAuthDataFromClient();
      // Reset the React Query status
      queryClient.removeQueries('me');
      queryClient.removeQueries('workspace');
      // Redirect to login page
      navigate(`/${ROUTING_URLS.LOGIN}`);
    } catch (error) {
      console.log('handleLogout error', error); //eslint-disable-line
    }
  };
  return (
    <ListItem>
      <div className="flex" onClick={handleLogout}>
        <LogoutIcn className={getColorClasses('', validPaths)} />
        <LabelItem className={getColorClasses('', validPaths)}>{t('LOGOUT')}</LabelItem>
      </div>
    </ListItem>
  );
};

export default Logout;
