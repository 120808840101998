import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LabelItem from '../LabelItem';
import ListItem from '../ListItem';
import { ReactComponent as MetricsIcn } from '../../../../icons/metrics-icon.svg';
import getColorClasses from '../../lib/getColorClasses';

const Metrics = ({ currentPath }) => {
  const { t } = useTranslation('PAGES');
  const validPaths = ['/app/metrics'];

  return (
    <ListItem>
      <Link to="/app/metrics" className="flex">
        <MetricsIcn className={getColorClasses(currentPath, validPaths)} />
        <LabelItem className={getColorClasses(currentPath, validPaths)}>{t('METRICS')}</LabelItem>
      </Link>
    </ListItem>
  );
};

export default Metrics;
