import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LabelItem from '../LabelItem';
import ListItem from '../ListItem';
import { ReactComponent as CompanyIcn } from '../../../../icons/companyProfile-icon.svg';
import getColorClasses from '../../lib/getColorClasses';

const CompanyProfile = ({ currentPath }) => {
  const { t } = useTranslation('PAGES');
  const validPaths = ['/app/workspace'];

  return (
    <ListItem>
      <Link to="/app/workspace" className="flex">
        <CompanyIcn className={getColorClasses(currentPath, validPaths)} />
        <LabelItem className={getColorClasses(currentPath, validPaths)}>{t('COMPANY_PROFILE')}</LabelItem>
      </Link>
    </ListItem>
  );
};

export default CompanyProfile;
