import React, { Suspense } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import SuspenseFallback from '../components/SuspenseFallback';
import { AUTH_TOKEN_NAME, ROUTING_URLS } from '../lib/constants';
import { getToken } from '../lib/token';
import useWhiteLabel from '../hooks/useWhiteLabel';

const PublicRoutes = () => {
  const authToken = getToken(AUTH_TOKEN_NAME);
  const location = useLocation();
  const from = location.state?.from?.pathname || '/app';
  const isWhiteLabel = useWhiteLabel();

  // if app is in white label and user is auth-ed goes to auth-ed homepage
  // else to custom page
  if (isWhiteLabel) {
    if (authToken) {
      return <Navigate to={from} replace />;
    } else if (location.pathname !== ROUTING_URLS.NOAUTH) {
      return <Navigate to={ROUTING_URLS.NOAUTH} replace />;
    }
  }

  // if user is auth-ed goes to /app or prev page and prevents the login page
  return authToken ? (
    <Navigate to={from} replace />
  ) : (
    <Suspense fallback={<SuspenseFallback />}>
      <Outlet />
    </Suspense>
  );
};

export default PublicRoutes;
