import { matchRoutes, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../lib/constants';

// Trasform the object of app routes in an array of object with path attribute. Ex: [{ path: '/app/team' }, { path: '/app/me' }, ...]
const routes = Object.values(APP_ROUTES).map((route) => ({ path: `app/${route}` }));

const useCurrentPathPattern = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  if (!matchedRoutes) {
    return null;
  }

  const [{ route }] = matchedRoutes;

  return route.path;
};

export default useCurrentPathPattern;
