import { ROUTING_URLS, USER_CAPABILITIES } from '../constants';

export const getFallbackPage = (me) => {
  const fallbackPage = process.env.REACT_APP_APP_FALLBACK_PAGE;
  const hasCashflow = me?.membership?.resources?.some((role) => role === USER_CAPABILITIES.CASHFLOW);
  if (!hasCashflow) {
    return `/${ROUTING_URLS.PREVISIONS}`;
  }
  return fallbackPage;
};
