import styled from 'styled-components';

export const SpecialModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 40;
  background-color: rgb(211 211 211 / 40%)
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
`;
