import React, { useEffect } from 'react';
import { useLoading } from '../Legacy/Commons/hooks/loading';

const FallbackComponent = () => {
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    showLoading();
    return () => hideLoading();
  }, []);

  return <div />;
};

export default FallbackComponent;
