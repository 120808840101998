import { useState } from 'react';

/**
 * Checks the value of the REACT_APP_WHITE_LABEL environment variable
 * @returns boolean - true if the REACT_APP_BRAND var is not pinv, false otherwise
 */
const useWhiteLabel = () => {
  const [isWhiteLabel] = useState(() => {
    const brand = process.env.REACT_APP_BRAND;
    if (brand !== 'pinv') {
      return true;
    }
    return false;
  });

  return isWhiteLabel;
};

export default useWhiteLabel;
