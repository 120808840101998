import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LabelItem from '../LabelItem';
import ListItem from '../ListItem';
import { ReactComponent as ProductsIcn } from '../../../../icons/products-icon.svg';
import getColorClasses from '../../lib/getColorClasses';

const Products = ({ currentPath }) => {
  const { t } = useTranslation('PAGES');
  const validPaths = ['/app/products'];

  return (
    <ListItem>
      <Link to="/app/products" className="flex">
        <ProductsIcn className={getColorClasses(currentPath, validPaths)} />
        <LabelItem className={getColorClasses(currentPath, validPaths)}>{t('PRODUCTS')}</LabelItem>
      </Link>
    </ListItem>
  );
};

export default Products;
