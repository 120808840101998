import useWorkspace from './useWorkspace';
import { ONBOARDING_STATUS } from '../lib/constants';

/**
 * Create the query for me object
 * @param {object|undefined} options The options to add to useQuery
 * @returns useQuery object
 */
const useOnboarding = () => {
  const { data: workspace, isLoading } = useWorkspace();

  const { onboarding: onboardingData } = workspace || {};

  const isOnboardingToConfirmPlan = () => {
    const { onboarding = {} } = workspace;
    const { status = '' } = onboarding;

    if (status === ONBOARDING_STATUS.TO_CONFIRM_PLAN) {
      return true;
    }
    return false;
  };

  const isOnboardingInTutorial = () => {
    const { onboarding = {} } = workspace;
    const { status = '' } = onboarding;

    if (status === ONBOARDING_STATUS.IN_TUTORIAL) {
      return true;
    }
    return false;
  };

  const isOnboardingDone = () => {
    const { onboarding = {} } = workspace;
    const { status = '' } = onboarding;

    if (status === ONBOARDING_STATUS.ONBOARDING_DONE) {
      return true;
    }
    return false;
  };

  const getOnboardingProgressData = () => {
    const { tasks = {} } = onboardingData;
    const totalCount = Object.keys(tasks).length;
    const trueCount = Object.values(tasks).filter(Boolean).length;
    const progressValue = (trueCount / totalCount) * 100;
    const progressLabel = trueCount + ' / ' + totalCount;

    return { progressValue, progressLabel };
  };

  const getTaskStatus = (taskKey) => {
    const { tasks = {} } = onboardingData;
    const valore = tasks[taskKey];

    const isEnabled = valore !== undefined;
    const isCompleted = valore === true;

    return { isEnabled, isCompleted };
  };

  const areAllTasksCompleted = () => {
    const { tasks = {} } = onboardingData;

    return Object.values(tasks).every((value) => value === true);
  };

  const canShowComponent = (taskKey) => {
    const { isEnabled, isCompleted } = getTaskStatus(taskKey);

    // Se la task non è abilitata per il workspace il componente è visibile sempre
    if (isOnboardingDone() || !isEnabled) return true;

    // Altrimenti è visibile solo se completata
    return isCompleted;
  };

  return {
    isLoading,
    isOnboardingToConfirmPlan,
    isOnboardingInTutorial,
    isOnboardingDone,
    getOnboardingProgressData,
    canShowComponent,
    areAllTasksCompleted,
    getTaskStatus,
  };
};

export default useOnboarding;
