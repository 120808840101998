import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import mergeClassNames from '../Legacy/Commons/helpers/mergeClassNames';
import Menu from '../Legacy/Menu/MenuContainer';
import SuspenseFallback from '../SuspenseFallback';

// Generic app layout with horizontal padding and max-width for the content
const LayoutWithMenu = ({ noPadding = false }) => {
  const className = mergeClassNames(
    'bg-neutro-100 mx-auto max-w-[1600px] sm:px-14 2xl:px-28',
    noPadding ? '' : 'pb-14',
  );

  return (
    <main id="app" className="ml-[73px] h-screen bg-neutro-100">
      <nav id="menu">
        <Menu />
      </nav>
      <section id="content" className={className}>
        <Suspense fallback={<SuspenseFallback />}>
          <Outlet />
        </Suspense>
      </section>
    </main>
  );
};

export default LayoutWithMenu;
