import React, { useState, useContext, createContext, useEffect } from 'react';
import Notification from './Notification';
import { useTranslation } from 'react-i18next';

const NOTIFICATION_LIFE_TIME = 3; //seconds

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const { t: tCommon } = useTranslation('COMMON');
  const [show, setShow] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [data, setData] = useState({ autoclose: true });

  const showNotification = ({ title, text, footer, type, options, autoclose = true, buttons = [] }) => {
    setData({
      title,
      text,
      footer,
      type,
      options,
      autoclose,
      buttons,
    });
    setSeconds(0);
    setShow(true);
  };

  useEffect(() => {
    let interval = null;

    if (data.autoclose) {
      if (show && seconds < NOTIFICATION_LIFE_TIME) {
        interval = setInterval(() => {
          setSeconds((seconds) => seconds + 1);
        }, 1000);
      } else {
        clearInterval(interval);
        setShow(false);
        setSeconds(0);
      }
    }

    return () => clearInterval(interval);
  }, [show, seconds, data.autoclose]);

  const notificationContext = {
    showNotification,
  };

  const getNotification = (data) => {
    switch (data.type) {
      default:
        return (
          <Notification type={data.type} data={data} show={show} setShow={setShow} buttons={data.buttons} t={tCommon} />
        );
    }
  };

  return (
    <NotificationContext.Provider value={notificationContext}>
      {getNotification(data)}
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export { NotificationProvider, useNotification };
