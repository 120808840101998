import axios from 'axios';
import { TOKEN_NAME } from '../constants';
import { getAuthHeaderConfig } from '../utils/utils';
import { getWorkspaceId } from '../workspace';

const fetchWorkspace = async () => {
  const workspaceId = getWorkspaceId();

  const httpConfig = {
    headers: getAuthHeaderConfig(TOKEN_NAME),
  };

  const { data } = await axios(`${process.env.REACT_APP_API_SERVER_URL}/workspaces/${workspaceId}`, httpConfig);

  return data;
};

export default fetchWorkspace;
