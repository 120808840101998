import React from 'react';

const LoadingSpinner = ({ fullPage = false }) => {
  let fullPageClass = '';

  if (fullPage) {
    fullPageClass = 'fixed inset-0 h-screen w-screen';
  }

  return (
    <div className={`flex justify-center items-center h-full z-50 ${fullPageClass}`}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
