import useWhiteLabel from '../hooks/useWhiteLabel';
import { isAllowed } from '../lib/auth/user/userHasPermission';

/**
 * Checks if render the component if the user is authorized or is in white label
 * @param {object} children Component to render or not
 * @param {object} me Me 'server side state' object retrieved from useQuery hook
 * @param {array} capabilities Array of capability codes
 * @param {boolean} renderInWhiteLabel Flag for render the component in white label or not, it works only in white label apps
 * @returns {object|null} The component to render or null
 */
const IsAuthorized = ({ children, me, capabilities, renderInWhiteLabel = true }) => {
  const isAuthorizedToRender = isAllowed(me, capabilities);
  const isWhiteLabel = useWhiteLabel();

  // Checks if the app is in white label mode and if flag is false
  if (isWhiteLabel && !renderInWhiteLabel) {
    return null;
  }

  if (isAuthorizedToRender) {
    return children;
  }
  return null;
};

export default IsAuthorized;
