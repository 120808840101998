import { ROUTING_URLS } from './constants';
import { isSubscriptionExpired } from './subscription';
import { redirectTo, resetAuthDataFromClient, verifyUserAuthentication } from './utils/utils';

const appBootstrap = async (workspace, isOnboardingToConfirmPlan) => {
  try {
    verifyUserAuthentication();

    if (workspace && workspace._id) {
      if (isOnboardingToConfirmPlan()) {
        redirectTo(ROUTING_URLS.ONBOARDING);
      } else if (isSubscriptionToPayment(workspace)) {
        redirectTo(ROUTING_URLS.SUBSCRIPTION);
      }
    }
  } catch (error) {
    // Reset the app status
    resetAuthDataFromClient();
    // Redirect to login page
    redirectTo(ROUTING_URLS.LOGIN);
  }
};

export const isSubscriptionToPayment = (workspace) => {
  if (!workspace) {
    throw new Error('The workspace parameter is required');
  }

  return isSubscriptionExpired(workspace);
};

export default appBootstrap;
