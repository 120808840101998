import React from 'react';
import useWhiteLabel from '../hooks/useWhiteLabel';
import BootstrapWhiteLabel from './Bootstrap/BootstrapWhiteLabel';
import BootstrapPinv from './Bootstrap/BootstrapPinv';

const Bootstrap = (props) => {
  if (useWhiteLabel()) {
    return <BootstrapWhiteLabel {...props} />;
  } else {
    return <BootstrapPinv {...props} />;
  }
};

export default Bootstrap;
