import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Legacy/Commons/TailwindComponents/buttons/Button';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation('ERRORS');
  const navigate = useNavigate();
  return (
    <>
      <div className="h-screen bg-neutro-100 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-bold tracking-tight text-primary-400 sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-neutro-400 sm:pl-6">
                <h1 className="font-bold tracking-tight text-neutro-900 sm:text-5xl">{t('NOT_FOUND')} </h1>
                <p className="mt-1 text-base text-neutro-500">{t('NOT_FOUND_ADDRESS')}</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Button onClick={() => navigate('/login')}>{t('GO_BACK')}</Button>
                <Button onClick={() => navigate('/app/help')} primary={true}>
                  {t('TO_SUPPORT')}
                </Button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default NotFound;
