import axios from 'axios';
import notifications from '../../../lib/utils/notifications';

export const legalInfoSave = (workspace, data) => {
  const { legal_info } = workspace;

  const workspaceData = {
    ...workspace,
    legal_info: {
      ...legal_info,
      address: {
        rue: data.Headquarters_Street,
        number: data.Headquarters_Number,
        zip: data.Headquarters_PostalCode,
        city: data.Headquarters_Municipality,
        state: data.Headquarters_District,
        nation: data.Headquarters_Nation,
      },
    },
  };
  return workspaceData;
};

export const operativeDataSave = (workspace, data, operativeData) => {
  //per ogni sede legale che ho nello stato 'operativeData', prendo i relativi dati dal register
  // e li uso per pushare in un array l'oggetto sede legale da inserire poi nel db
  let operative_info = [];
  for (let i = 0; i < operativeData.length; i++) {
    const sede = [
      {
        address: {
          rue: data[`${i}Operational_Street`],
          number: data[`${i}Operational_Number`],
          zip: data[`${i}Operational_PostalCode`],
          city: data[`${i}Operational_Municipality`],
          state: data[`${i}Operational_District`],
          nation: data[`${i}Operational_Nation`],
        },
      },
    ];
    operative_info.push(...sede);
  }

  const workspaceData = {
    ...workspace,
    operative_info: operative_info,
  };
  return workspaceData;
};

export const legalRepresentativeSave = (workspace, data) => {
  const workspaceData = {
    ...workspace,
    legal_representative: {
      firstname: data.LegalRepresentative_Firstname,
      lastname: data.LegalRepresentative_Lastname,
      fiscal_code: data.LegalRepresentative_FiscalCode.toUpperCase(),
      birthdate: new Date(data.LegalRepresentative_Birthdate),
      birthplace: data.LegalRepresentative_Birthplace,
      citizenship: data.LegalRepresentative_Citizenship,
      homeAddress: {
        address: data.Residence_Street,
        buildingNumber: data.Residence_Number,
        postalCode: data.Residence_PostalCode,
        city: data.Residence_Municipality,
        province: data.Residence_District,
        country: data.Residence_Nation,
      },
      currentAddress: {
        address: data.Domicile_Street,
        buildingNumber: data.Domicile_Number,
        postalCode: data.Domicile_PostalCode,
        city: data.Domicile_Municipality,
        province: data.Domicile_District,
        country: data.Domicile_Nation,
      },
      contacts: {
        email: data.LegalRepresentative_Email,
        mobile: data.LegalRepresentative_Telephone,
      },
    },
  };
  return workspaceData;
};

export const bankSave = (workspace, data) => {
  const workspaceData = {
    ...workspace,
    bank: {
      name: data.bank,
      agency: data.filiale,
      IBAN: data.iban,
      note: data.note,
    },
  };
  return workspaceData;
};

export const paymentConditionSave = (workspace, data) => {
  const { invoice_option = {} } = workspace;
  const { it = {} } = invoice_option;

  const workspaceData = {
    ...workspace,
    invoice_option: {
      ...invoice_option,
      end_of_month: data.end_of_month,
      deadline: data.deadline,
      it: {
        ...it,
        esigibilita_iva: data.esigibilita_iva,
      },
    },
  };

  return workspaceData;
};

export const paypalSave = (workspace, data) => {
  const workspaceData = {
    ...workspace,
    paypal: {
      url_me: data.urlMe,
      is_default: data.isDefault,
    },
  };

  return workspaceData;
};

export const regimeFiscaleSave = (workspace, data) => {
  const { invoice_option = {} } = workspace;
  const { it = {}, rivalsa_inps = {} } = invoice_option;

  let enasarco = {};
  // populate enasarco object only if tipo cassa is ENASARCO
  if (data.regime_cassa === 'TC07') {
    enasarco = data.contributo_previdenziale.enasarco;
  }

  const workspaceData = {
    ...workspace,
    invoice_option: {
      ...invoice_option,
      it: {
        ...it,
        contributo_previdenziale: {
          tipo: data.regime_cassa,
          nome: data.name_cassa,
          valore: data.value_cassa,
          percentuale: data.imponibile_cassa,
          tax: data.aliquota_cassa,
          nature: data.natura_cassa,
          enasarco,
        },
        rivalsa_inps: {
          ...rivalsa_inps,
          valore: data.rivalsa_inps,
          tax: data.aliquota_inps,
          nature: data.natura_inps,
        },
        gestione_contributo_previdenziale: data.cassaPrev,
        gestione_marca_da_bollo: data.bollo,
        gestione_ritenuta_dacconto: data.ritenuta,
        gestione_separata_inps: data.gestioneInps,
        regime_fiscale: data.fiscal_regimes,
        ritenuta_dacconto: data.aliquota_ritenuta,
        percentuale_ritenuta_dacconto: data.imponibile_ritenuta,
        tipo_ritenuta: data.tipo_ritenuta,
        marca_da_bollo: data.valore_bollo,
        payment_iva_period: data.payment_iva_period,
        detraibility_iva_percentage: data.percentuale_detraibilita_iva,
      },
    },
  };
  return workspaceData;
};

export const getDefaultLegalRepRegister = (legal_representative) => {
  const {
    contacts = {},
    homeAddress = {},
    currentAddress = {},
    firstname = '',
    lastname = '',
    birthdate = '',
    fiscal_code = '',
    birthplace = '',
    citizenship = '',
  } = legal_representative;
  const { email = '', mobile = '' } = contacts;
  const {
    address: Domicile_address = '',
    buildingNumber: Domicile_houseNumber = '',
    postalCode: Domicile_postalCode = '',
    city: Domicile_municipality = '',
    province: Domicile_district = '',
    country: Domicile_nation = '',
  } = currentAddress;
  const {
    address: Residence_address = '',
    buildingNumber: Residence_houseNumber = '',
    postalCode: Residence_postalCode = '',
    city: Residence_municipality = '',
    province: Residence_district = '',
    country: Residence_nation = '',
  } = homeAddress;
  const legalRepresentativeRegister = {
    Residence_Street: Residence_address,
    Residence_Number: Residence_houseNumber,
    Residence_PostalCode: Residence_postalCode,
    Residence_Municipality: Residence_municipality,
    Residence_District: Residence_district,
    Residence_Nation: Residence_nation,
    Domicile_Street: Domicile_address,
    Domicile_Number: Domicile_houseNumber,
    Domicile_PostalCode: Domicile_postalCode,
    Domicile_Municipality: Domicile_municipality,
    Domicile_District: Domicile_district,
    Domicile_Nation: Domicile_nation,
    LegalRepresentative_Firstname: firstname,
    LegalRepresentative_Lastname: lastname,
    LegalRepresentative_Email: email,
    LegalRepresentative_Telephone: mobile,
    LegalRepresentative_Birthdate: new Date(birthdate),
    LegalRepresentative_FiscalCode: fiscal_code,
    LegalRepresentative_Birthplace: birthplace,
    LegalRepresentative_Citizenship: citizenship,
  };
  return legalRepresentativeRegister;
};

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const fetchCompanyLogoFromS3 = async (companyLogo) => {
  try {
    const { data = {}, headers } = await axios.get(companyLogo);

    // If content type is application/xml it is an error: https://docs.aws.amazon.com/AmazonS3/latest/API/ErrorResponses.html
    if (headers['content-type'] !== 'application/xml') {
      const { workspaceLogo } = data;
      return workspaceLogo;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getCompanyLogo = async (token) => {
  try {
    const { data = {}, status } = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/workspaces/image`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (status === 200) {
      const { companyLogo } = data;
      const currentTimestamp = Date.now();
      return `${companyLogo}?${currentTimestamp}`;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getS3SignedUrl = async (token, fileName) => {
  try {
    const { data = {}, status } = await axios.post(
      `${process.env.REACT_APP_API_SERVER_URL}/workspaces/image/uploadurl`,
      {
        key: fileName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (status === 200) {
      const { signedUrl } = data;
      return signedUrl;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const uploadCompanyLogo = async (token, file, companyLogo) => {
  try {
    const signedUrl = await getS3SignedUrl(token, companyLogo);

    await axios.put(signedUrl, file);

    const image = await toBase64(file);
    return image;
  } catch (e) {
    return '';
  }
};

export const updateLogoFileName = async (token, id, fileName) => {
  try {
    await axios.patch(
      `${process.env.REACT_APP_API_SERVER_URL}/workspaces/${id}`,
      {
        logoFileName: fileName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

export const deleteLogoFileName = async (token) => {
  try {
    await axios.delete(`${process.env.REACT_APP_API_SERVER_URL}/workspaces/image`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getNotifications = async (token) => {
  const { data: apiNotifications } = await axios(`${process.env.REACT_APP_API_SERVER_URL}/notifications`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const parsedNotifications = apiNotifications.map((code) => notifications()[code]);

  return parsedNotifications;
};
