import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Bootstrap from './Bootstrap';
import { AUTH_TOKEN_NAME, ROUTING_URLS } from '../lib/constants';
import { getToken } from '../lib/token';

const RequireAuth = ({ capabilities, layout, renderInWhiteLabel }) => {
  const authToken = getToken(AUTH_TOKEN_NAME);
  const location = useLocation();

  return authToken ? (
    <Bootstrap capabilities={capabilities} layout={layout} renderInWhiteLabel={renderInWhiteLabel} />
  ) : (
    <Navigate to={ROUTING_URLS.NOAUTH} state={{ from: location }} replace />
  );
};

export default RequireAuth;
