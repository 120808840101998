import React from 'react';
import mergeClassNames from '../../helpers/mergeClassNames';

const Button = (props) => {
  const {
    children,
    onClick,
    type = 'button',
    primary = false,
    active = false,
    danger = false,
    size = 'medium',
    className = '',
    ...otherProps
  } = props;
  let newClassName = mergeClassNames(className, 'btn');

  switch (size) {
    case 'small':
      newClassName = mergeClassNames(newClassName, 'btn-small');
      break;
    case 'large':
      newClassName = mergeClassNames(newClassName, 'btn-large');
      break;
    case 'full':
      newClassName = mergeClassNames(newClassName, 'btn-full');
      break;
    case 'empty':
      break;
    default:
      newClassName = mergeClassNames(newClassName, 'btn-medium');
  }

  if (otherProps.disabled) {
    //creo bottone grigiastro che non fa nulla
    if (primary) {
      newClassName = mergeClassNames(newClassName, 'btn-primary-disabled');
    } else {
      newClassName = mergeClassNames(newClassName, 'btn-secondary-disabled');
    }
  } else {
    if (active) {
      //creo bottone con sfondo blu scuro e testo bianco
      newClassName = mergeClassNames(newClassName, 'btn-tertiary');
    }
    if (primary) {
      //creo bottone con sfondo azzurro e testo bianco
      newClassName = mergeClassNames(newClassName, 'btn-primary');
    }
    if (danger) {
      //creo bottone con sfondo rosso e testo bianco
      newClassName = mergeClassNames(newClassName, 'btn-danger');
    }
    if (!primary && !active && !danger) {
      //creo bottone con sfondo bianco e bordi colorati
      newClassName = mergeClassNames(newClassName, 'btn-secondary');
    }
  }

  return (
    <button onClick={onClick} type={type} className={newClassName} {...otherProps}>
      {children}
    </button>
  );
};

export default Button;
