import React, { lazy, Suspense, useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { allCapabilities } from '../lib/auth/user/userHasPermission';
import { APP_LAYOUTS, USER_CAPABILITIES } from '../lib/constants';
import RequireAuth from './RequireAuth';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import PublicRoutes from './PublicRoutes';
import SuspenseFallback from '../components/SuspenseFallback';
import { DOC_TYPES } from '../components/Legacy/constants';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const RegisterInvite = lazy(() => import('../pages/Invitations/RegisterInvite'));
const RegisterCollaborator = lazy(() => import('../pages/Invitations/RegisterCollaborator'));
const VerifyEmail = lazy(() => import('../pages/VerifyEmail'));
const NotAuthenticated = lazy(() => import('../pages/NotAuthenticated'));
const NotFound = lazy(() => import('../pages/NotFound'));

const Dashboard = lazy(() => import('../pages/app/Dashboard'));
const Subscription = lazy(() => import('../pages/app/subscriptions/Subscription'));
const Team = lazy(() => import('../pages/app/Team'));
const Help = lazy(() => import('../pages/app/Help'));
const Onboarding = lazy(() => import('../pages/app/Onboarding'));
const Faq = lazy(() => import('../pages/app/Faq'));
const CompanyProfile = lazy(() => import('../pages/app/CompanyProfile'));
const Workspaces = lazy(() => import('../pages/app/Workspaces'));
const NotFoundAuthenticated = lazy(() => import('../pages/app/NotFoundAuthenticated'));
const OnboardingSedeLegale = lazy(() => import('../pages/app/OnboardingSedeLegale'));
const Metrics = lazy(() => import('../pages/app/Metrics'));
const CreditnotesList = lazy(() => import('../pages/app/creditnotes/CreditnotesList'));
const CreditnotesCreate = lazy(() => import('../pages/app/creditnotes/CreditnotesCreate'));
const CreditnotesDetail = lazy(() => import('../pages/app/creditnotes/CreditnotesDetail'));
const CreditnotesInList = lazy(() => import('../pages/app/creditnotes-in/CreditnotesInList'));
const CreditnoteInCreate = lazy(() => import('../pages/app/creditnotes-in/CreditnotesInCreate'));
const CreditnoteInDetail = lazy(() => import('../pages/app/creditnotes-in/CreditnotesInDetail'));
const InvoicesInList = lazy(() => import('../pages/app/invoices-in/InvoicesInList'));
const InvoiceInCreate = lazy(() => import('../pages/app/invoices-in/InvoiceInCreate'));
const InvoiceInDetail = lazy(() => import('../pages/app/invoices-in/InvoiceInDetail'));
const InvoicesList = lazy(() => import('../pages/app/invoices/InvoicesList'));
const InvoiceCreate = lazy(() => import('../pages/app/invoices/InvoiceCreate'));
const InvoiceDetail = lazy(() => import('../pages/app/invoices/InvoiceDetail'));
const InvoiceUpload = lazy(() => import('../pages/app/invoices/InvoiceUpload'));
const ExpensesList = lazy(() => import('../pages/app/expenses/ExpensesList'));
const ExpenseCreate = lazy(() => import('../pages/app/expenses/ExpenseCreate'));
const ExpenseDetail = lazy(() => import('../pages/app/expenses/ExpenseDetail'));
const TaxesList = lazy(() => import('../pages/app/taxes/TaxesList'));
const TaxCreate = lazy(() => import('../pages/app/taxes/TaxCreate'));
const TaxDetail = lazy(() => import('../pages/app/taxes/TaxDetail'));
const ProductsList = lazy(() => import('../pages/app/products/ProductsList'));
const ProductCreate = lazy(() => import('../pages/app/products/ProductCreate'));
const ProductDetail = lazy(() => import('../pages/app/products/ProductDetail'));
const QuotesList = lazy(() => import('../pages/app/quotes/QuotesList'));
const QuoteCreate = lazy(() => import('../pages/app/quotes/QuoteCreate'));
const QuoteDetail = lazy(() => import('../pages/app/quotes/QuoteDetail'));
const ProformasList = lazy(() => import('../pages/app/proformas/ProformasList'));
const ProformaCreate = lazy(() => import('../pages/app/proformas/ProformaCreate'));
const ProformaDetail = lazy(() => import('../pages/app/proformas/ProformaDetail'));
// const OrdersList = lazy(() => import('../pages/app/orders/OrdersList'));
// const OrderCreate = lazy(() => import('../pages/app/orders/OrderCreate'));
// const OrderDetail = lazy(() => import('../pages/app/orders/OrderDetail'));
const DdtsList = lazy(() => import('../pages/app/ddts/DdtsList'));
const DdtCreate = lazy(() => import('../pages/app/ddts/DdtCreate'));
const DdtDetail = lazy(() => import('../pages/app/ddts/DdtDetail'));
const WorkreportsList = lazy(() => import('../pages/app/workreports/WorkreportsList'));
const WorkreportsCreate = lazy(() => import('../pages/app/workreports/WorkreportsCreate'));
const WorkreportsDetail = lazy(() => import('../pages/app/workreports/WorkreportsDetail'));
const CustomersList = lazy(() => import('../pages/app/Customers/CustomersList'));
const CustomerDetail = lazy(() => import('../pages/app/Customers/CustomerDetail'));
const CassettoFiscale = lazy(() => import('../pages/app/cassettoFiscale/CassettoFiscale'));
const CassettoFiscaleCheck = lazy(() => import('../pages/app/cassettoFiscale/CassettoFiscaleCheck'));
const SpidLogin = lazy(() => import('../pages/app/spidLogin/SpidLogin'));
const SpidLoginCheck = lazy(() => import('../pages/app/spidLogin/SpidLoginCheck'));
const Documentale = lazy(() => import('../pages/app/Documentale'));
const MovementsPinv = lazy(() => import('../pages/app/movements/MovementsPinv'));
const MovementsWhiteLabel = lazy(() => import('../pages/app/movements/MovementsWhitelabel'));
const Recurrences = lazy(() => import('../pages/app/Recurrences'));
const UserProfile = lazy(() => import('../pages/app/UserProfile'));
const ManualBank = lazy(() => import('../pages/app/ManualBank'));
const Finances = lazy(() => import('../pages/app/Finances'));
const Accounts = lazy(() => import('../pages/app/Accounts'));
const SendEmail = lazy(() => import('../pages/app/SendEmail'));
const Partners = lazy(() => import('../pages/app/partners/Partners'));
const QontoPartner = lazy(() => import('../pages/app/partners/QontoPartner'));
const TaxCoachPartner = lazy(() => import('../pages/app/partners/TaxCoachPartner'));
const ChangeCapitalPartner = lazy(() => import('../pages/app/partners/ChangeCapitalPartner'));
const SENTRY_DSN = process.env.SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const AppRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="/verify/:token" element={<VerifyEmail />} />
      <Route element={<PublicRoutes />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset/:token" element={<ResetPassword />} />
        <Route path="/invited" element={<RegisterInvite />} />
        <Route path="/noauth" element={<NotAuthenticated />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.CASHFLOW]} />}>
        <Route path="dashboard" element={<Dashboard />} />
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.METRICS]} />}>
        <Route path="metrics" element={<Metrics />} />
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.BANKS]} />}>
        <Route path="movements/:accountId" element={<MovementsPinv />} />
        <Route path="movements/sme/:smeAccountId" element={<MovementsWhiteLabel />} />
        <Route path="create-manual-bank" element={<ManualBank />} />
      </Route>
      <Route
        path="/app"
        element={<RequireAuth layout={APP_LAYOUTS.WITH_MENU_NO_PADDING} capabilities={[USER_CAPABILITIES.BANKS]} />}>
        <Route path="accounts" element={<Accounts />} />
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.DOC_IN]} />}>
        <Route path="invoices-in">
          <Route index element={<InvoicesInList />} />
          <Route path="create" element={<InvoiceInCreate />} />
          <Route path=":invoiceInId" element={<InvoiceInDetail />} />
          <Route path="upload" element={<InvoiceUpload docType={DOC_TYPES.INVOICEIN} />} />
        </Route>
        <Route path="creditnotes-in">
          <Route index element={<CreditnotesInList />} />
          <Route path="create" element={<CreditnoteInCreate />} />
          <Route path=":creditnoteInId" element={<CreditnoteInDetail />} />
          <Route path="upload" element={<InvoiceUpload docType={DOC_TYPES.CREDITNOTEIN} />} />
        </Route>
        <Route path="expenses">
          <Route index element={<ExpensesList />} />
          <Route path="create" element={<ExpenseCreate />} />
          <Route path=":expenseId" element={<ExpenseDetail />} />
        </Route>
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.DOC_OUT]} />}>
        <Route path="quotes">
          <Route index element={<QuotesList />} />
          <Route path="create" element={<QuoteCreate />} />
          <Route path=":quoteId" element={<QuoteDetail />} />
        </Route>
        {/* <Route path="orders">
          <Route index element={<OrdersList />} />
          <Route path="create" element={<OrderCreate />} />
          <Route path=":orderId" element={<OrderDetail />} />
        </Route> */}
        <Route path="ddts">
          <Route index element={<DdtsList />} />
          <Route path="create" element={<DdtCreate />} />
          <Route path=":ddtId" element={<DdtDetail />} />
        </Route>
        <Route path="proformas">
          <Route index element={<ProformasList />} />
          <Route path="create" element={<ProformaCreate />} />
          <Route path=":proformaId" element={<ProformaDetail />} />
        </Route>
        <Route path="invoices">
          <Route index element={<InvoicesList />} />
          <Route path="create" element={<InvoiceCreate />} />
          <Route path=":invoiceId" element={<InvoiceDetail />} />
          <Route path="upload" element={<InvoiceUpload docType={DOC_TYPES.INVOICE} />} />
        </Route>
        <Route path="cassetto-fiscale" element={<CassettoFiscale />} />
        <Route path="cassetto-fiscale-check" element={<CassettoFiscaleCheck />} />
        <Route path="spid-login" element={<SpidLogin />} />
        <Route path="spid-login-check" element={<SpidLoginCheck />} />
        <Route path="creditnotes">
          <Route index element={<CreditnotesList />} />
          <Route path="create" element={<CreditnotesCreate />} />
          <Route path=":creditnoteId" element={<CreditnotesDetail />} />
          <Route path="upload" element={<InvoiceUpload docType={DOC_TYPES.CREDITNOTE} />} />
        </Route>
        <Route path="workreports">
          <Route index element={<WorkreportsList />} />
          <Route path="create" element={<WorkreportsCreate />} />
          <Route path=":workreportsId" element={<WorkreportsDetail />} />
        </Route>
        <Route path="products">
          <Route index element={<ProductsList />} />
          <Route path="create" element={<ProductCreate />} />
          <Route path=":productId" element={<ProductDetail />} />
        </Route>
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.DOC_IN, USER_CAPABILITIES.DOC_OUT]} />}>
        <Route path="recurrences" element={<Recurrences />} />
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.TAXES]} />}>
        <Route path="taxes">
          <Route index element={<TaxesList />} />
          <Route path="create" element={<TaxCreate />} />
          <Route path=":taxId" element={<TaxDetail />} />
        </Route>
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.DOCUMENTAL]} />}>
        <Route path="documentale" element={<Documentale />} />
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.CUSTOMERS]} />}>
        <Route path="customers">
          <Route index element={<CustomersList />} />
          <Route path=":accountId" element={<CustomerDetail />} />
        </Route>
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={[USER_CAPABILITIES.COMPANY]} />}>
        <Route path="workspace" element={<CompanyProfile />} />
      </Route>
      <Route
        path="/app"
        element={<RequireAuth capabilities={allCapabilities()} layout={APP_LAYOUTS.WITHOUT_MENU_NO_PADDING} />}>
        <Route path="workspaces" element={<Workspaces />} />
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={allCapabilities()} renderInWhiteLabel={false} />}>
        <Route path="team" element={<Team />} />
        <Route path="subscription">
          <Route index element={<Subscription />} />
        </Route>
        <Route path="partners">
          <Route index element={<Partners />} />
          <Route path="qonto" element={<QontoPartner />} />
          <Route path="change-capital" element={<ChangeCapitalPartner />} />
          <Route path="tax-coach" element={<TaxCoachPartner />} />
        </Route>
        <Route path="finances" element={<Finances />} />
        <Route path="onboarding-sede-legale" element={<OnboardingSedeLegale />} />
      </Route>
      <Route path="/app" element={<RequireAuth capabilities={allCapabilities()} />}>
        <Route index element={<Navigate to="/app/dashboard" replace />} />
        <Route path="send-email/:documentId" element={<SendEmail />} />
        <Route path="faq" element={<Faq />} />
        <Route path="me" element={<UserProfile />} />
        <Route path="*" element={<NotFoundAuthenticated />} />
      </Route>
      <Route
        path="/app"
        element={<RequireAuth layout={APP_LAYOUTS.WITH_MENU_NO_PADDING} capabilities={allCapabilities()} />}>
        <Route path="help" element={<Help />} />
      </Route>
      <Route
        path="/invitedCollaborator"
        element={
          <Suspense fallback={<SuspenseFallback />}>
            <RegisterCollaborator />
          </Suspense>
        }
      />
      <Route
        path="/app"
        element={<RequireAuth layout={APP_LAYOUTS.WITHOUT_MENU_NO_PADDING} capabilities={allCapabilities()} />}>
        <Route path="onboarding" element={<Onboarding />} />
      </Route>
    </SentryRoutes>
  );
};

export default AppRoutes;
