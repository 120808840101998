import React from 'react';
import LayoutFullWidth from './LayoutFullWidth';
import LayoutWithMenu from './LayoutWithMenu';
import LayoutWithoutMenu from './LayoutWithoutMenu';
import { APP_LAYOUTS } from '../../lib/constants';

const RenderLayout = ({ layout }) => {
  switch (layout) {
    case APP_LAYOUTS.WITH_MENU:
      return <LayoutWithMenu />;
    case APP_LAYOUTS.WITHOUT_MENU:
      return <LayoutWithoutMenu />;
    case APP_LAYOUTS.FULL_WIDTH:
      return <LayoutFullWidth />;
    case APP_LAYOUTS.WITH_MENU_NO_PADDING:
      return <LayoutWithMenu noPadding />;
    case APP_LAYOUTS.WITHOUT_MENU_NO_PADDING:
      return <LayoutWithoutMenu noPadding />;
    default:
      return <LayoutWithMenu />;
  }
};

export default RenderLayout;
