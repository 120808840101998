import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LabelItem from '../LabelItem';
import ListItem from '../ListItem';
import { ReactComponent as ProfileIcn } from '../../../../icons/userProfile-icon.svg';
import getColorClasses from '../../lib/getColorClasses';

const UserProfile = ({ currentPath, userVerified }) => {
  const { t } = useTranslation('PAGES');
  const validPaths = ['/app/me'];

  const colorClasses =
    userVerified || currentPath === '/app/me'
      ? getColorClasses(currentPath, validPaths)
      : 'text-warning-400 fill-warning-400';

  return (
    <ListItem>
      <Link to="/app/me" className="flex">
        <ProfileIcn className={colorClasses} />
        <LabelItem className={colorClasses}>{t('USER_PROFILE')}</LabelItem>
      </Link>
    </ListItem>
  );
};

export default UserProfile;
