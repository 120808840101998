// Types definitions
export type ActionType = 'SET_SUBSCRIPTION' | 'SPID_LOGIN' | 'RETURN_PATH';

export type Action<T extends ActionType> = T extends 'SET_SUBSCRIPTION' | 'SPID_LOGIN'
  ? { type: T; payload: boolean }
  : T extends 'RETURN_PATH'
  ? { type: T; payload: string }
  : never;

export type UserLifecycleState = {
  isSubscriptionActive: boolean;
  hasSpidLoggedIn: boolean;
  canSendDocument: boolean;
  returnPath: string;
};

const initialState: UserLifecycleState = {
  isSubscriptionActive: false,
  hasSpidLoggedIn: false,
  canSendDocument: false,
  returnPath: '/',
};

export const userLifecycleReducer = (
  state: UserLifecycleState = initialState,
  action: Action<ActionType>,
): UserLifecycleState => {
  switch (action.type) {
    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        isSubscriptionActive: action.payload,
        canSendDocument: action.payload && state.hasSpidLoggedIn,
      };

    case 'SPID_LOGIN':
      return {
        ...state,
        hasSpidLoggedIn: action.payload,
        canSendDocument: action.payload && state.isSubscriptionActive,
      };

    case 'RETURN_PATH':
      return {
        ...state,
        returnPath: action.payload,
      };

    default:
      return state;
  }
};
