import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { ROUTING_URLS } from '../../lib/constants';
import { getMsFromMinutes, resetAuthDataFromClient } from '../../lib/utils/utils';
import fetchMe from '../../lib/api/me';

const useKeepAlive = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // timeout in ms: 5 minutes + 1 second (SME session timeout after 5 minutes)
  const timeoutTimeMs = getMsFromMinutes(process.env.REACT_APP_TIMEOUT_KEEPALIVE_MINUTES) + 1000;
  // throttle in ms: 1 minute
  const throttleTimeMs = 60000;

  const resetUserAuth = () => {
    // Reset the app status
    resetAuthDataFromClient();
    // Reset the React Query status
    queryClient.removeQueries('me');
    queryClient.removeQueries('workspace');
    // Redirect to noauth page
    navigate(ROUTING_URLS.NOAUTH, { replace: true });
  };

  const handleOnIdle = async () => {
    console.log('handleOnIdle getTotalActiveTime()', getTotalActiveTime()); //eslint-disable-line
    reset(); //reset totalActiveTime

    fetchMe().catch((error) => {
      if (error?.response?.status === 401) {
        resetUserAuth();
      }
    });
  };

  const handleOnAction = async () => {
    console.log('handleOnAction getTotalActiveTime()', getTotalActiveTime()); //eslint-disable-line
    fetchMe().catch((error) => {
      if (error?.response?.status === 401) {
        resetUserAuth();
      }
    });
  };

  console.log('useKeepAlive timeoutTimeMs', timeoutTimeMs); //eslint-disable-line
  const idleTimer = useIdleTimer({
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    timeout: timeoutTimeMs,
    throttle: throttleTimeMs,
    // debounce: debounceTimeMs,
  });

  const { getTotalActiveTime, reset } = idleTimer;

  return idleTimer;
};

export default useKeepAlive;
