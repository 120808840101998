import { useQuery } from '@tanstack/react-query';
import fetchWorkspace from '../lib/api/workspace';
import { WORKSPACE_QUERY_NAME } from '../lib/constants';
import { getMsFromMinutes } from '../lib/utils/utils';

/**
 * Create the query for me object
 * @param {object|undefined} options The options to add to useQuery
 * @returns useQuery object
 */
const useWorkspace = (options) => {
  if (options !== undefined && typeof options !== 'object') {
    throw new Error('The options is not an object');
  }

  const queryOptions = {
    staleTime: getMsFromMinutes(process.env.REACT_APP_TIMEOUT_KEEPALIVE_MINUTES || 3),
    ...options,
  };

  return useQuery([WORKSPACE_QUERY_NAME], fetchWorkspace, queryOptions);
};

export default useWorkspace;
