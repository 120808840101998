import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isAllowed } from '../../lib/auth/user/userHasPermission';
import useMe from '../../hooks/useMe';
import useWorkspace from '../../hooks/useWorkspace';
import useOnboarding from '../../hooks/useOnboarding';
import LoadingSpinner from '../../components/Legacy/Commons/TailwindComponents/LoadingSpinner';
import appBootstrap from '../../lib/appBootstrap';
import { ROUTING_URLS } from '../../lib/constants';
import { resetAuthDataFromClient } from '../../lib/utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import RenderLayout from '../../components/Layout/RenderLayout';
import { saveDataToClient } from '../../lib/auth/login';
import { getFallbackPage } from '../../lib/utils/getFallbackPage';
import { useUserLifecycleContext } from '../../providers/UserLifecycleProvider';
import { isSpidLoggedIn } from '../../lib/onboarding';
import { isSubscriptionActive } from '../../lib/subscription';

interface BootstrapPinvProps<C, L> {
  capabilities: C[];
  layout: L;
}

const BootstrapPinv = <C, L>({ capabilities, layout }: BootstrapPinvProps<C, L>) => {
  const { data: me, isLoading: isLoadingMe, isError: isErrorMe } = useMe(undefined);
  const {
    data: workspace,
    isLoading: isLoadingWorkspace,
    isError: isErrorWorkspace,
  } = useWorkspace({ enabled: !!me?._id });
  const { dispatch } = useUserLifecycleContext();
  const { isOnboardingToConfirmPlan } = useOnboarding();
  const isAuthorizedToRender = isAllowed(me, capabilities);
  const location = useLocation();
  const fallbackPage = getFallbackPage(me);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const runAppBootstrap = async () => {
      await appBootstrap(workspace, isOnboardingToConfirmPlan);
    };

    if (!isLoadingWorkspace) {
      runAppBootstrap();
      dispatch({ type: 'SPID_LOGIN', payload: isSpidLoggedIn(workspace) });
      dispatch({ type: 'SET_SUBSCRIPTION', payload: isSubscriptionActive(workspace) });
    }
  }, [location, isLoadingWorkspace]);

  // Checks if the me and workspace query retrieve error then reset app client data and redirect to logout
  useEffect(() => {
    if (!isLoadingMe || !isLoadingWorkspace) {
      if (isErrorMe || isErrorWorkspace) {
        // Reset the app status
        resetAuthDataFromClient();
        // Reset the React Query status
        queryClient.removeQueries(['me', 'workspace']);
        // Redirect to login page
        navigate(ROUTING_URLS.LOGIN, { replace: true });
      } else {
        // save data to client
        if (me) saveDataToClient(me);
      }
    }
  }, [isLoadingWorkspace, isLoadingMe, isErrorWorkspace, isErrorMe]);

  if (isLoadingWorkspace || isLoadingMe) {
    return <LoadingSpinner fullPage />;
  }

  return isAuthorizedToRender ? (
    <RenderLayout layout={layout} />
  ) : (
    <Navigate to={fallbackPage || '/'} state={{ from: location }} replace />
  );
};

export default BootstrapPinv;
