import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LabelItem from '../LabelItem';
import ListItem from '../ListItem';
import { ReactComponent as TeamsIcn } from '../../../../icons/teams-icon.svg';
import getColorClasses from '../../lib/getColorClasses';

const UserManagement = ({ currentPath }) => {
  const { t } = useTranslation('PAGES');
  const validPaths = ['/app/team'];

  return (
    <ListItem>
      <Link to="/app/team" className="flex">
        <TeamsIcn className={getColorClasses(currentPath, validPaths)} />
        <LabelItem className={getColorClasses(currentPath, validPaths)}>{t('TEAM_PROFILE')}</LabelItem>
      </Link>
    </ListItem>
  );
};

export default UserManagement;
