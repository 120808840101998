import React from 'react';
import Menu from './Menu';

const MenuContainer = () => {
  return (
    <div className="m-0 fixed left-0 border-r border-solid border-secondary-400 bg-white top-0 bottom-0 px-4 py-0 overflow-x-hidden w-[73px] scrollbar-none z-30 hover:w-56 transition-[width] duration-200 group">
      <Menu />
    </div>
  );
};

export default MenuContainer;
