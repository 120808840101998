import * as Sentry from '@sentry/react';
import { AUTH_TOKEN_NAME } from '../constants';
import { getAuthHeaderConfig } from '../utils/utils';
import axios from 'axios';

const fetchMe = async (workspaceId = null) => {
  const httpConfig = {
    headers: getAuthHeaderConfig(AUTH_TOKEN_NAME),
    params: {
      workspaceId,
    },
  };

  const { data } = await axios(`${process.env.REACT_APP_API_SERVER_URL}/me`, httpConfig);

  const { _id: id, _workspace: workspace } = data || {};
  Sentry.configureScope((scope) => scope.setUser({ id, workspace }));

  return data;
};

export default fetchMe;
