/*eslint no-useless-catch: "off"*/
import { makeHttpGet } from './apiClient';
import { TOKEN_NAME } from './constants';
import { getAuthHeaderConfig } from './utils/utils';

const getWorkspaceId = () => {
  return localStorage.getItem('pf.workspaceid') || '';
};

const saveWorkspaceId = (id) => {
  return localStorage.setItem('pf.workspaceid', id);
};

const deleteWorkspaceId = () => {
  return localStorage.removeItem('pf.workspaceid');
};

const getWorkspaceData = async () => {
  try {
    const workspaceId = getWorkspaceId();

    const config = {
      headers: getAuthHeaderConfig(TOKEN_NAME),
    };

    const responseData = await makeHttpGet(`${process.env.REACT_APP_API_SERVER_URL}/workspaces/${workspaceId}`, config);

    return responseData;
  } catch (error) {
    throw error;
  }
};

export { getWorkspaceId, saveWorkspaceId, deleteWorkspaceId, getWorkspaceData };
