import { getCompanyLogo } from '../../components/Legacy/CompanyProfile/api';
import { AUTH_TOKEN_NAME, ROUTING_URLS, TOKEN_NAME } from '../constants';
import pinvTracker from '../pinvTracker';
import { deleteSubscriptionPlan } from '../subscription';
import { deleteToken, getToken } from '../token';
import { deleteUserId, isEmailVerified } from '../user';
import { isWorkspaceInTrial } from '../subscription';
import { deleteWorkspaceId } from '../workspace';

/**
 * Delete the tokens from cookie and app data from local storage
 */
const resetAuthDataFromClient = () => {
  // Reset the app status
  deleteToken(TOKEN_NAME);
  deleteToken(AUTH_TOKEN_NAME);
  deleteUserId();
  deleteWorkspaceId();
  deleteSubscriptionPlan();
};

/**
 * Redirect to pathname
 * @param {string} pathname The pathname destination to redirect
 */
const redirectTo = (pathname) => {
  if (!pathname) {
    throw new Error('The pathname parameter is required');
  }

  // Check if current pathname is not the same for redirect, otherwise it run in infinite loop
  if (!window.location.pathname.includes(pathname)) {
    // Redirect to pathname page
    window.location.replace(`/${pathname}`);
  }
};

/**
 * Verify if the authToken cookie exists: if not exists then reset the client app data and redirect to login page
 */
const verifyUserAuthentication = () => {
  const authToken = getToken(AUTH_TOKEN_NAME);
  if (!authToken) {
    // Reset the app status
    resetAuthDataFromClient();
    // Redirect to login page
    redirectTo(ROUTING_URLS.LOGIN);
  }
};

/**
 * Transform minutes in milliseconds
 * @param {number} minutes The minutes value to transform in milliseconds
 * @returns {number} The milliseconds from minutes parameter
 */
const getMsFromMinutes = (minutes) => {
  return 1000 * 60 * parseInt(minutes);
};

/**
 * Create header for api auth
 * @param {string} tokenName
 */
const getAuthHeaderConfig = (tokenName) => {
  if (!tokenName) {
    throw new Error('Param tokenName is null');
  }

  const token = getToken(tokenName);

  const config = {
    Authorization: `Bearer ${token}`,
  };

  return config;
};

/**
 * Fetch and return the company url logo.
 * @param {object} workspace The workspace object
 * @returns {string|null|Error} The company url logo
 */
const getCompanyUrlLogo = async (workspace) => {
  if (!workspace) {
    throw new Error('The workspace parameter is null.');
  }
  if (!workspace.logoFileName) {
    return null;
  }

  let urlLogo = '';
  try {
    const token = getToken(TOKEN_NAME);
    urlLogo = await getCompanyLogo(token);
  } catch (error) {
    pinvTracker(error);
  }

  return urlLogo;
};

/**
 * Get the error msg from an api error object received from a call to our BE
 * @param {object} apiError
 * @returns {string} Error message
 */
const getApiErrorMessage = (apiError = {}) => {
  const errorMsg = apiError.response?.data?.error?.message || apiError.response?.data?.message || '';

  return errorMsg;
};

/**
 * Check if data contain errors, because some BE's apis returns 200 with errors in the data
 * @param {object} dataReceived
 */
const checkIfDataContainError = (dataReceived = {}) => {
  if (dataReceived.error) {
    const errorMsg = dataReceived.error.message || 'Errore generico';
    throw new Error(errorMsg);
  }
};

/**
 * checks if the workspace user pair can send an einvoice
 *
 * @param {object} workspace - The workspace object.
 * @param {object} user - The user object.
 * @returns {boolean} Returns true if an e-invoice can be sent, otherwise false.
 */
const canSendEinvoice = ({ workspace, user }) => {
  if (!isEmailVerified(user)) {
    return { canSend: false, errorMsg: 'REQUIRED_EMAIL_VERIFICATION' };
  }

  if (isWorkspaceInTrial(workspace)) {
    return { canSend: false, errorMsg: 'REQUIRED_PLAN_ACTIVATION' };
  }

  return { canSend: true };
};

export {
  resetAuthDataFromClient,
  redirectTo,
  verifyUserAuthentication,
  getMsFromMinutes,
  getAuthHeaderConfig,
  getCompanyUrlLogo,
  getApiErrorMessage,
  checkIfDataContainError,
  canSendEinvoice,
};
