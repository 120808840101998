import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LabelItem from '../LabelItem';
import ListItem from '../ListItem';
import { ReactComponent as AccountsIcn } from '../../../../icons/accounts-icon.svg';
import getColorClasses from '../../lib/getColorClasses';

const Accounts = ({ currentPath }) => {
  const { t } = useTranslation('PAGES');
  const validPaths = ['/app/accounts'];

  return (
    <ListItem>
      <Link to="/app/accounts" className="flex">
        <AccountsIcn className={getColorClasses(currentPath, validPaths)} />
        <LabelItem className={getColorClasses(currentPath, validPaths)}>{t('RECONCILIATION')}</LabelItem>
      </Link>
    </ListItem>
  );
};

export default Accounts;
