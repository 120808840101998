import { NavigateFunction } from 'react-router-dom';
import { UserLifecycleState } from './userLifecycleReducer';
import { ROUTING_URLS } from '../../lib/constants';

export interface HandleSendingResult {
  enableSending: boolean;
}

export const handleSending = async (
  state: UserLifecycleState,
  navigate: NavigateFunction,
): Promise<HandleSendingResult> => {
  let enableSending = true;

  if (!state.canSendDocument) {
    enableSending = false;

    if (!state.isSubscriptionActive) {
      setTimeout(() => navigate(ROUTING_URLS.SUBSCRIPTION), 100);
    } else if (!state.hasSpidLoggedIn) {
      setTimeout(() => navigate(ROUTING_URLS.SPID_LOGIN), 100);
    }
  }

  return { enableSending };
};
