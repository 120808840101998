import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isAllowed } from '../../lib/auth/user/userHasPermission';
import useMe from '../../hooks/useMe';
import useWorkspace from '../../hooks/useWorkspace';
import LoadingSpinner from '../../components/Legacy/Commons/TailwindComponents/LoadingSpinner';
import appBootstrap from '../../lib/appBootstrap';
import useCurrentPathPattern from '../../hooks/useCurrentPathPattern';
import { ROUTING_URLS } from '../../lib/constants';
import { resetAuthDataFromClient } from '../../lib/utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import RenderLayout from '../../components/Layout/RenderLayout';
import useKeepAlive from '../../hooks/sme/useKeepAlive';
import { saveDataToClient } from '../../lib/auth/login';
import { getFallbackPage } from '../../lib/utils/getFallbackPage';
import useOnboarding from '../../hooks/useOnboarding';

const BootstrapWhiteLabel = ({ capabilities, renderInWhiteLabel = true, layout }) => {
  const { data: me, isLoading: isLoadingMe, isError: isErrorMe } = useMe();
  const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace({ enabled: !!me?._id });
  const { isOnboardingToConfirmPlan } = useOnboarding();
  const isAuthorizedToRender = isAllowed(me, capabilities);
  const location = useLocation();
  const fallbackPage = getFallbackPage(me);
  const navigate = useNavigate();
  const currentPathPattern = useCurrentPathPattern();
  const queryClient = useQueryClient();

  useKeepAlive();

  // Checks if the app is in white label mode and if flag is false
  useEffect(() => {
    if (!renderInWhiteLabel) {
      navigate(fallbackPage);
    }
  }, [renderInWhiteLabel]);

  useEffect(() => {
    const runAppBootstrap = async () => {
      await appBootstrap(workspace, isOnboardingToConfirmPlan);
    };

    if (!isLoadingWorkspace) {
      runAppBootstrap(workspace, currentPathPattern);
    }
  }, [location, isLoadingWorkspace]);

  // Checks if the me and workspace query retrieve error then reset app client data and redirect to logout
  useEffect(() => {
    console.log('BootstrapWhiteLabel isLoadingMe, isErrorMe', isLoadingMe, isErrorMe); //eslint-disable-line
    if (isErrorMe) {
      console.log('BootstrapWhiteLabel if (isErrorMe)'); //eslint-disable-line
      // Reset the app status
      resetAuthDataFromClient();
      // Reset the React Query status
      queryClient.removeQueries('me');
      queryClient.removeQueries('workspace');
      // Redirect to noauth page
      navigate(ROUTING_URLS.NOAUTH, { replace: true });
    } else if (!isLoadingMe) {
      console.log('BootstrapWhiteLabel else if (!isLoadingMe)', me); //eslint-disable-line
      // save data to client
      saveDataToClient(me);
    }
  }, [isLoadingMe, isErrorMe]);

  if (isLoadingWorkspace || isLoadingMe) {
    return <LoadingSpinner fullPage />;
  }

  return isAuthorizedToRender ? (
    <RenderLayout layout={layout} />
  ) : (
    <Navigate to={fallbackPage} state={{ from: location }} replace />
  );
};

export default BootstrapWhiteLabel;
