/*eslint no-useless-catch: "off"*/
import axios from 'axios';
import { checkIfDataContainError } from './utils/utils';

const makeHttpGet = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    checkIfDataContainError(response);

    if (response.status !== 200) {
      throw new Error(`The POST Request to ${url} does not respond with status 200`);
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

const makeHttpPost = async (url, data, config) => {
  try {
    const response = await axios.post(url, data, config);
    checkIfDataContainError(response);

    if (response.status !== 200) {
      throw new Error(`The POST Request to ${url} not responde with status 200`);
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

const makeHttpPut = async (url, data, config) => {
  try {
    const response = await axios.put(url, data, config);
    checkIfDataContainError(response);

    if (response.status !== 200) {
      throw new Error(`The POST Request to ${url} not responde with status 200`);
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export { makeHttpGet, makeHttpPost, makeHttpPut };
