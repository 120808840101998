/**
 * @typedef {{ title: string, message: string, actions: Array.<{ label: string, externalLink: boolean, redirect: string }}} notification
 */
/**
 * @type {{notifications: Object.<string, notification>}}
 */

/**
 * Returns an object containing notification messages.
 *
 * @type {{notifications: Object.<string, notification>}}
 * @returns {Object.<string, { title: string, message: string, actions: Array.<{ label: string, externalLink: boolean, redirect: string }>, autoclose: boolean }>} An object containing notification messages.
 * @property {Object} - The notification for expired Fabrick consents.
 * @property {string} title - The title of the notification.
 * @property {string} message - The message of the notification.
 * @property {Array<Object>} actions - An array of action objects to display in the notification.
 * @property {string} actions.label - The label of the action button.
 * @property {boolean} actions.externalLink - A flag indicating whether the action is an external link or not.
 * @property {string} actions.redirect - The URL to redirect the user to when the action is clicked.
 * @property {boolean} autoclose - A flag indicating whether the notification should be automatically closed or not.
 */
const notifications = () => {
  return {
    FABRICK_CONSENTS_EXPIRED: {
      title: 'FABRIC_CONSENTS_EXPIRED.TITLE',
      message: 'FABRIC_CONSENTS_EXPIRED.MESSAGE',
      actions: [
        {
          label: 'FABRIC_CONSENTS_EXPIRED.LABEL',
          externalLink: true,
          redirect: process.env.REACT_APP_FABRICK_LOGIN_URL,
        },
      ],
      autoclose: false,
    },
  };
};

export default notifications;
